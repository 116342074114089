<template>
  <textarea
    v-model="value"
    v-bind="$attrs"
    :class="{error}"
  />
</template>

<script>
export default {
  name: 'ATextarea',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  }
}
</script>