<template>
  <img
    :src="src"
    alt="Attendee Avatar"
  >
</template>

<script>
export default {
  name: 'AGAvatar',
  props: {
    hash: {
      type: String,
      required: true
    },
    size: {
      type: [String, Number],
      default: 100
    }
  },
  data: () => ({
    url: 'https://www.gravatar.com/avatar'
  }),
  computed: {
    src() {
      return `${this.url}/${this.hash}?s=${this.size}&d=mp`;
    }
  }
};
</script>