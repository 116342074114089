<template>
  <error-layout>
    <div class="max-w-2xl mx-auto">
      <div class="bg-white overflow-hidden shadow rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          Page Not Found!
        </div>
        <div class="bg-gray-100 px-4 py-5 sm:p-6">
          <div class="flex justify-between">
            <a-button
              variant="secondary"
              @click="onBack"
            >
              Go Back
            </a-button>
            <a-button @click="onHomePage">
              Back To Homepage
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </error-layout>
</template>

<script>
import ErrorLayout from '@/layouts/error.vue';
export default {
  components: {
    ErrorLayout
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    },
    onHomePage() {
      this.$router.replace({ name: 'home' });
    }
  }
};
</script>