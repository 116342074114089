import http from '@/services/http';

const state = {};

const getters = {};

const actions = {
  async email(c, {conference, email}) {
    try {
      const { data: { data } } = await http.post(`conferences/${conference}/user/magic-link/email`, { email });
      
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async attempt(c, {conference, uuid}) {
    try {
      const { data: { data } } = await http.post(`conferences/${conference}/user/magic-link/confirm/${uuid}`);
      
      return data
    } catch (e) {
      return Promise.reject(e);
    }
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
