import http from '@/services/http';

const state = {};

const getters = {
  conference: (state, getters, rootState, rootGetters) =>
    rootGetters['conferences/current'],
  exhibitor: (state, getters, rootState, rootGetters) =>
    rootGetters['exhibitors/exhibitor/slug']
};

const actions = {
  async create({ dispatch, getters }, {product, form}) {
    try {
      await http.post(`conferences/${getters.conference}/exhibitors/${getters.exhibitor}/products/${product}/images`, form)

      const data = await dispatch('exhibitors/exhibitor/get', getters.exhibitor, {root: true});

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async update({ dispatch, getters }, {product, id, form}) {
    try {
      await http.put(`conferences/${getters.conference}/exhibitors/${getters.exhibitor}/products/${product}/images/${id}`, form)

      const data = await dispatch('exhibitors/exhibitor/get', getters.exhibitor, {root: true});

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async delete({ getters, dispatch }, {product, id}) {
    try {
      await http.delete(`conferences/${getters.conference}/exhibitors/${getters.exhibitor}/products/${product}/images/${id}`)

      const data = await dispatch('exhibitors/exhibitor/get', getters.exhibitor, {root: true});

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
