<template>
  <span
    role="checkbox"
    v-bind="$attrs"
    :aria-checked="isCheckedOrSelected"
    :class="switchClass"
    @click.stop="onToggleCheck"
    @keydown.space.prevent="onToggleCheck"
  >
    <span
      aria-hidden="true"
      :class="toggleClass"
    >
      <a-icon
        v-if="loading"
        name="circle-notch"
        type="fad"
        size="sm"
        class="text-black ml-1"
        spin
      />
    </span>
  </span>
</template>

<script>
import variant from '@/mixins/variant.js';
import checkbox from '@/mixins/checkbox.js';
export default {
  name: 'ASwitch',
  mixins: [variant, checkbox],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    switchClass() {
      return [
        'switch',
        `switch--${this.variant}`,
        this.loading ? 'switch--loading' : null,
        this.disabled ? 'switch--disabled' : null,
        this.isCheckedOrSelected
          ? `switch--on switch--${this.variant}--on`
          : `switch--off switch--${this.variant}--off`
      ];
    },
    toggleClass() {
      return [
        'switch__toggle',
        `switch__toggle--${this.variant}`,
        this.loading ? 'switch__toggle--loading' : null,
        this.disabled ? 'switch__toggle--disabled' : null,
        this.isCheckedOrSelected
          ? `switch__toggle--on switch__toggle--${this.variant}--on`
          : `switch__toggle--off switch__toggle--${this.variant}--off`
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.switch {
  @apply relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline;

  &--off {
    @apply bg-gray-300;
  }

  &__toggle {
    @apply translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200;

    &--on {
      @apply translate-x-5;
    }
  }

  &--primary--on {
    @apply bg-primary;
  }

  &--secondary--on {
    @apply bg-secondary;
  }

  &--danger--on {
    @apply bg-danger;
  }

  &--success--on {
    @apply bg-success;
  }

  &--disabled,
  &--loading {
    @apply bg-gray-500 cursor-not-allowed;
  }
}
</style>