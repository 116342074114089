import http from "@/services/http";
import { find, get } from "@/utils/lodash"

const state = {
  options: []
};

const getters = {
  option: state => (code, defaultValue = null) => {
    let option = find(state.options, option => option.code == code);

    return get(option, 'value', defaultValue);
  }
};

const actions = {
  async all({ commit }) {
    let { data: { data }} = await http.get('options');

    commit('SET_OPTIONS', data)

    return data;
  }
};

const mutations = {
  SET_OPTIONS(state, options) {
    state.options = options;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
