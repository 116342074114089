import http from '@/services/http';

const state = {};

const getters = {
  conference: (state, getters, rootState, rootGetters) =>
    rootGetters['conferences/current']
};

const actions = {
  async details({ commit, getters }, payload) {
    try {
      const { data: { data } } = await http.post(`conferences/${getters.conference}/account/details`, payload)

      commit('auth/USER_ME', data, {root: true})

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async savePassword({ getters }, payload) {
    try {
      const { data: { data } } = await http.post(`conferences/${getters.conference}/account/password-change`, payload)

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async savePicture({ commit, getters }, path) {
    try {
      const { data: { data } } = await http.post(`conferences/${getters.conference}/account/save-picture`, {image: path})

      commit('auth/USER_ME', data, {root: true})

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
