import Component from './notifications.vue'

export const Plugin = {
  install(app) {
    const manager = (variantOrObject, params) => {
      let variant = variantOrObject
      if (typeof params === 'undefined') {
        params = variantOrObject
        variant = variantOrObject.variant || ''
      }

      if (typeof params === 'string') {
        params = { text: params }
      }
      if (typeof params === 'object') {
        app.config.globalProperties.$events.emit('create', {variant, params})
      }
    }

    manager.close = id => app.config.globalProperties.$events.emit('remove', id)

    manager.clear = group => app.config.globalProperties.$events.emit('clear', group)

    manager.clearAll = () => app.config.globalProperties.$events.emit('clear-all')

    app.component('ANotification', Component)

    const notify = manager

    app.config.globalProperties.$notify = notify
  }
};

export default Plugin
