const state = {
  debug: false
};

const getters = {};

const actions = {};

const mutations = {
  SET_DEBUG(state, value) {
    state.debug = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
