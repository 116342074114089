<template>
  <label
    v-bind="$attrs"
    :class="{error}"
  >
    <slot />
  </label>
</template>

<script>
export default {
  name: 'ALabel',
  props: {
    error: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  @apply block text-sm leading-5 text-gray-500 py-1;
  &.error {
    @apply text-danger;
  }
}
</style>