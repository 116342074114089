<template>
  <div
    role="group"
    class="relative z-0 inline-flex button-select"
  >
    <a-button
      v-for="(option, i) of options"
      :key="getOptionRenderKey(option)"
      :aria-label="getOptionLabel(option)"
      role="button"
      :variant="variant(option)"
      :aria-pressed="isSelected(option)"
      :tabindex="isOptionDisabled(option) ? null : '0'"
      :aria-labelledby="ariaLabelledBy"
      :class="getButtonClass(option)"
      class="relative inline-flex items-center rounded-none focus:outline-none focus:border-none focus:shadow-outline-none"
      v-bind="$attrs"
      @click="onOptionSelect($event, option, i)"
      @keydown.enter.prevent="onOptionSelect($event, option, i)"
      @keydown.space.prevent="onOptionSelect($event, option)"
      @focus="onFocus($event)"
      @blur="onBlur($event)"
    >
      <slot
        name="option"
        :option="option"
        :index="i"
      >
        <span>{{ getOptionLabel(option) }}</span>
      </slot>
    </a-button>
  </div>
</template>

<script>
import ObjectUtils from '@/utils/ObjectUtils';
export default {
  name: 'AButtonSelect',
  props: {
    modelValue: {
      type: [String, Number, Array, Object],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    optionLabel: {
      type: String,
      default: null
    },
    optionValue: {
      type: String,
      default: null
    },
    optionDisabled: {
      type: Boolean,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dataKey: {
      type: String,
      default: null
    },
    ariaLabelledBy: {
      type: String,
      default: null
    },
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  computed: {
      equalityKey() {
          return this.optionValue ? null : this.dataKey;
      }
  },
  methods: {
      getOptionLabel(option) {
          return this.optionLabel ? ObjectUtils.resolveFieldData(option, this.optionLabel) : option;
      },
      getOptionValue(option) {
          return this.optionValue ? ObjectUtils.resolveFieldData(option, this.optionValue) : option;
      },
      getOptionRenderKey(option) {
          return this.dataKey ? ObjectUtils.resolveFieldData(option, this.dataKey) : this.getOptionLabel(option);
      },
      isOptionDisabled(option) {
          return this.optionDisabled ? ObjectUtils.resolveFieldData(option, this.optionDisabled) : false;
      },
      onOptionSelect(event, option) {
          if (this.disabled || this.isOptionDisabled(option)) {
              return;
          }

          let selected = this.isSelected(option);
          let optionValue = this.getOptionValue(option);
          let newValue;

          if(this.multiple) {
              if (selected)
                  newValue = this.modelValue.filter(val => !ObjectUtils.equals(val, optionValue, this.equalityKey));
              else
                  newValue = this.modelValue ? [...this.modelValue, optionValue]: [optionValue];
          }
          else {
              newValue = optionValue;
          }

          this.$emit('update:modelValue', newValue);
      },
      isSelected(option) {
          let selected = false;
          let optionValue = this.getOptionValue(option);

          if (this.multiple) {
              if (this.modelValue) {
                  for (let val of this.modelValue) {
                      if (ObjectUtils.equals(val, optionValue, this.equalityKey)) {
                          selected = true;
                          break;
                      }
                  }
              }
          }
          else {
              selected = ObjectUtils.equals(this.modelValue, optionValue, this.equalityKey);
          }

          return selected;
      },
      variant(option) {
        return this.isSelected(option) ? 'primary' : 'white';
      },
      onFocus(event) {
          this.$emit('focus', event);
      },
      onBlur(event) {
          this.$emit('blur', event);
      },
      getButtonClass(option) {
          return {
              'highlight': this.isSelected(option),
              'disabled': this.isOptionDisabled(option)
          };
      }
  }
}
</script>

<style lang="scss" scoped>
.button-select {
  button:first-child {
    @apply rounded-l-md border-r-0;
  }

  button:last-child {
    @apply rounded-r-md border-l-0;
  }
  
  .disabled {
    @apply bg-gray-300 cursor-not-allowed;
  }
}
</style>