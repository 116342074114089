<script>
export default {
  name: 'AAvatarStack',
}
</script>

<template>
  <div class="avatars-stack">
    <slot />
  </div>
</template>
