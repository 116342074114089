import { find, includes } from '@/utils/lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import timezones from 'timezones.json';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.tz.guess();

export default dayjs;

export function format(date, format = 'llll') {
  return dayjs(date, { timeZone: 'UTC' }).format(format);
}

export function formatAsIs(date, format = 'llll') {
  return dayjs(date).format(format);
}

export function formatUtc(date, format) {
  return dayjs(date).utc().format(format);
}

export function isDateBetween(date, from, to) {
  return dayjs(date).isBetween(from, to);
}

export function isAfter(comparedTo, date) {
  return dayjs(date).isAfter(comparedTo);
}

let tz = dayjs.tz.guess();
export const abbreviation = timezoneAbbreviation();
export function timezoneAbbreviation() {
  return find(timezones, timezone => includes(timezone.utc, tz));
}
