import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes.js';
import store from '@/store';
import { get, includes } from '@/utils/lodash';

// const isDevelopement = process.env.NODE_ENV === 'development'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// if(isDevelopement) {
//   router.addRoute(require('./guide.js').default)
// }

router.beforeEach(async (to, from, next) => {
  let hasToken = store.getters['auth/hasToken'];
  let isGuest = get(to.meta, 'guest');
  let current = store.getters['conferences/current'];

  // Management
  let requiresManager = get(to.meta, 'manager');
  let isManager = store.getters['management/isManager'];

  // If we have a token but not a loaded user then load the user now
  if (!isGuest && !hasToken && (current = get(to.params, 'conference'))) {
    next({ name: 'auth', params: { conference: current } });
    return;
  }

  if (!isGuest && !hasToken && current) {
    next({ name: 'auth', params: { conference: current } });
    return;
  }

  if (isGuest && hasToken && current && to.name != 'home') {
    next({ name: 'dashboard', params: { conference: current } });
    return;
  }

  // Management Routes
  if(requiresManager && current && !isManager) {
    next({ name: 'dashboard', params: { conference: current } });
    return;
  }

  // Virtual Booths/Booth Routes
  if(to.name == 'virtual.booth' && store.getters['auth/isUser']) {
    const canViewBooth = store.getters['auth/canViewBooth'];
    if(!canViewBooth) {
      next({ name: 'dashboard', params: { conference: to.params.conference } });
      return;
    }
  } else if (includes(to.path, '/exhibitors') && store.getters['auth/isUser']) {
    const canViewExhibitors = store.getters['auth/canViewExhibitors'];
    if(!canViewExhibitors) {
      next({ name: 'dashboard', params: { conference: to.params.conference } });
      return;
    }
  } else if (includes(to.path, '/attendees') && store.getters['auth/isUser']) {
    const canViewAttendees = store.getters['conferences/hasAttendeesList'];
    if(!canViewAttendees) {
      next({ name: 'dashboard', params: { conference: to.params.conference } });
      return;
    }
  }

  next();
});

export default router;
