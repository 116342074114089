// UI
import Avatar from '@/components/ui/avatars/avatar.vue';
import AvatarStack from '@/components/ui/avatars/stack.vue';
import Accordion from '@/components/ui/accordion/accordion.vue';
import AccordionTab from '@/components/ui/accordion/tab.vue';
import Alert from '@/components/ui/alert.vue';
import Card from '@/components/ui/card.vue';
import Badge from '@/components/ui/badge.vue';
import ButtonSelect from '@/components/ui/button-select.vue';
import Icon from '@/components/ui/icons/icon.vue';
import Gavatar from '@/components/ui/gravatar.vue';
import FileUpload from '@/components/ui/file-upload.vue';
import Heading from '@/components/ui/heading.vue';
import HeroBanner from '@/components/ui/hero-banner.vue';
import Modal from '@/components/ui/modal.vue';
import NavigationGroup from '@/components/ui/navigation/group.vue';
import ProgressBar from '@/components/ui/progress/bar.vue';
import Tab from '@/components/ui/tabs/tab.vue';
import Tabs from '@/components/ui/tabs/tabs.vue';

// Forms
import Button from '@/components/ui/forms/button.vue';
import Dropdown from '@/components/ui/forms/dropdown.vue';
import Input from '@/components/ui/forms/input.vue';
import Help from '@/components/ui/forms/help.vue';
import Form from '@/components/ui/forms/form.vue';
import Label from '@/components/ui/forms/label.vue';
import Switch from '@/components/ui/forms/switch.vue';
import Textarea from '@/components/ui/forms/textarea.vue';
import Toggle from '@/components/ui/forms/toggle.vue';
import MultiSelect from '@/components/ui/forms/multi-select.vue';

// Directives
import Tooltip from "@/components/ui/directives/tooltip.js";

const components = [
  Alert,
  Avatar,
  AvatarStack,
  Accordion,
  AccordionTab,
  ButtonSelect,
  FileUpload,
  ProgressBar,
  Badge,
  Card,
  Gavatar,
  Heading,
  HeroBanner,
  Modal,
  NavigationGroup,
  Icon,
  Button,
  Dropdown,
  Input,
  Help,
  Form,
  Label,
  Switch,
  Textarea,
  Tab,
  Tabs,
  Toggle,
  MultiSelect
];

export const Plugin = {
  install(app) {
    components.forEach(component => app.component(component.name, component));

    app.directive('tooltip', Tooltip)
  }
};

export default Plugin;

export { components };
