<template>
  <div
    v-if="isAdvanced"
    class="fileupload fileupload-advanced"
  >
    <div class="fileupload-buttonbar flex flex-row">
      <div :class="advancedChooseButtonClass">
        <a-button
          tabindex="0"
          :loading="loading"
          :size="buttonSize"
          @click="choose"
          @keydown.enter="choose"
          @focus="onFocus"
          @blur="onBlur"
        >
          <input
            ref="fileInput"
            type="file"
            :multiple="multiple"
            :accept="accept"
            :disabled="chooseDisabled"
            @change="onFileSelect"
          >
          <slot name="label">
            <a-icon
              name="plus"
              class="mr-2"
            />
            <span>{{ chooseLabel }}</span>
          </slot>
        </a-button>
      </div>
      <a-button
        :size="buttonSize"
        :disabled="uploadDisabled"
        @click="upload"
      >
        <a-icon
          name="upload"
          class="mr-2"
        />
        <span>{{ uploadLabel }}</span>
      </a-button>
      <a-button
        :size="buttonSize"
        :disabled="cancelDisabled"
        @click="clear"
      >
        <a-icon
          name="times"
          class="mr-2"
        /> 
        <span>{{ cancelLabel }}</span>
      </a-button>
    </div>
    <div
      ref="content"
      class="fileupload-content"
      @dragenter="onDragEnter"
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDrop"
    >
      <a-progress-bar
        v-if="hasFiles"
        :value="progress"
      />
      <a-alert
        v-for="msg of messages"
        :key="msg"
        type="error"
      >
        {{ msg }}
      </a-alert>
      <div
        v-if="hasFiles"
        class="fileupload-files"
      >
        <div
          v-for="(file, index) of files"
          :key="file.name + file.type + file.size"
          class="fileupload-row"
        >
          <div>
            <img
              v-if="isImage(file)"
              role="presentation"
              :alt="file.name"
              :src="file.objectURL"
              :width="previewWidth"
            >
          </div>
          <div>{{ file.name }}</div>
          <div>{{ formatSize(file.size) }}</div>
          <div>
            <a-button
              size="sm"
              variant="danger"
              @click="remove(index)"
            >
              <a-icon name="times" />
            </a-button>
          </div>
        </div>
      </div>
      <div
        v-if="$slots.empty && !hasFiles"
        class="fileupload-empty"
      >
        <slot name="empty" />
      </div>
    </div>
  </div>
  <div
    v-else-if="isBasic"
    class="fileupload fileupload-basic"
  >
    <a-alert
      v-for="msg of messages"
      :key="msg"
      type="error"
    >
      {{ msg }}
    </a-alert>
    <div :class="basicChooseButtonClass">
      <a-button 
        tabindex="0"
        :size="buttonSize"
        :loading="loading"
        @mouseup="onBasicUploaderClick"
        @keydown.enter="choose"
        @focus="onFocus"
        @blur="onBlur"
      >
        <slot name="label">
          <a-icon
            v-if="basicButtonIcon"
            :name="basicButtonIcon"
            class="mr-2"
          />
          <span>{{ basicChooseButtonLabel }}</span>
        </slot>
      </a-button>
      <input
        v-if="!hasFiles"
        ref="fileInput"
        type="file"
        :accept="accept"
        :disabled="disabled"
        @change="onFileSelect"
        @focus="onFocus"
        @blur="onBlur"
      >
    </div>
  </div>
</template>

<script>
import DomHandler from '@/utils/dom';

export default {
  name: 'AFileUpload',
  props: {
    name: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    buttonSize: {
      type: String,
      default: 'sm'
    },
    mode: {
      type: String,
      default: 'basic'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    auto: {
      type: Boolean,
      default: false
    },
    maxFileSize: {
      type: [Number, String],
      default: null
    },
    invalidFileSizeMessage: {
      type: String,
      default: '{0}: Invalid file size, file size should be smaller than {1}.'
    },
    fileLimit: {
      type: Number,
      default: null
    },
    invalidFileLimitMessage: {
      type: String,
      default: 'Maximum number of files exceeded, limit is {0} at most.'
    },
    withCredentials: {
      type: Boolean,
      default: false
    },
    previewWidth: {
      type: Number,
      default: 50
    },
    chooseLabel: {
      type: String,
      default: 'Browse'
    },
    uploadLabel: {
      type: String,
      default: 'Upload'
    },
    cancelLabel: {
      type: String,
      default: 'Cancel'
    },
    customUpload: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'select',
    'uploader',
    'before-upload',
    'progress',
    'upload',
    'error',
    'before-send',
    'clear'
  ],
  duplicateIEEvent: false,
  data() {
    return {
      uploadedFileCount: 0,
      files: [],
      messages: [],
      focused: false,
      progress: null
    };
  },
  computed: {
    isAdvanced() {
      return this.mode === 'advanced';
    },
    isBasic() {
      return this.mode === 'basic';
    },
    advancedChooseButtonClass() {
      return [
        'fileupload-choose',
        {
          disabled: this.disabled,
          focus: this.focused
        }
      ];
    },
    basicChooseButtonClass() {
      return [
        'fileupload-choose',
        {
          'fileupload-choose-selected': this.hasFiles,
          disabled: this.disabled,
          focus: this.focused
        }
      ];
    },
    basicChooseButtonLabel() {
      return this.auto
        ? this.chooseLabel
        : this.hasFiles
        ? this.files[0].name
        : this.chooseLabel;
    },
    basicButtonIcon() {
      if (this.hasFiles && !this.auto) {
        return 'upload';
      } else if (!this.hasFiles || this.auto) {
        return 'plus';
      }

      return null;
    },
    hasFiles() {
      return this.files && this.files.length > 0;
    },
    chooseDisabled() {
      return (
        this.disabled ||
        (this.fileLimit &&
          this.fileLimit <= this.files.length + this.uploadedFileCount)
      );
    },
    uploadDisabled() {
      return this.disabled || !this.hasFiles;
    },
    cancelDisabled() {
      return this.disabled || !this.hasFiles;
    }
  },
  methods: {
    onFileSelect(event) {
      if (event.type !== 'drop' && this.isIE11() && this.duplicateIEEvent) {
        this.duplicateIEEvent = false;
        return;
      }

      this.messages = [];
      this.files = this.files || [];
      let files = event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files;
      for (let file of files) {
        if (!this.isFileSelected(file)) {
          if (this.validate(file)) {
            if (this.isImage(file)) {
              file.objectURL = window.URL.createObjectURL(file);
            }
            this.files.push(file);
          }
        }
      }

      this.$emit('select', { originalEvent: event, files: files });

      if (this.fileLimit) {
        this.checkFileLimit();
      }

      if (this.auto && this.hasFiles && !this.isFileLimitExceeded()) {
        this.upload();
      }

      if (event.type !== 'drop' && this.isIE11()) {
        this.clearIEInput();
      } else {
        this.clearInputElement();
      }
    },
    choose() {
      this.$refs.fileInput.click();
    },
    upload() {
      if (this.customUpload) {
        if (this.fileLimit) {
          this.uploadedFileCount += this.files.length;
        }

        this.$emit('uploader', { files: this.files });
      } else {
        let xhr = new XMLHttpRequest();
        let formData = new FormData();

        this.$emit('before-upload', {
          xhr: xhr,
          formData: formData
        });

        for (let file of this.files) {
          formData.append(this.name, file, file.name);
        }

        xhr.upload.addEventListener('progress', event => {
          if (event.lengthComputable) {
            this.progress = Math.round((event.loaded * 100) / event.total);
          }

          this.$emit('progress', {
            originalEvent: event,
            progress: this.progress
          });
        });

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            this.progress = 0;

            if (xhr.status >= 200 && xhr.status < 300) {
              if (this.fileLimit) {
                this.uploadedFileCount += this.files.length;
              }

              this.$emit('upload', {
                xhr: xhr,
                files: this.files
              });
            } else {
              this.$emit('error', {
                xhr: xhr,
                files: this.files
              });
            }

            this.clear();
          }
        };

        xhr.open('POST', this.url, true);

        this.$emit('before-send', {
          xhr: xhr,
          formData: formData
        });

        xhr.withCredentials = this.withCredentials;

        xhr.send(formData);
      }
    },
    clear() {
      this.files = [];
      this.messages = null;
      this.$emit('clear');

      if (this.isAdvanced) {
        this.clearInputElement();
      }
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    isFileSelected(file) {
      if (this.files && this.files.length) {
        for (let sFile of this.files) {
          if (
            sFile.name + sFile.type + sFile.size ===
            file.name + file.type + file.size
          )
            return true;
        }
      }

      return false;
    },
    isIE11() {
      return !!window['MSInputMethodContext'] && !!document['documentMode'];
    },
    validate(file) {
      if (this.maxFileSize && file.size > this.maxFileSize) {
        this.messages.push(
          this.invalidFileSizeMessage
            .replace('{0}', file.name)
            .replace('{1}', this.formatSize(this.maxFileSize))
        );
        return false;
      }

      return true;
    },
    onDragEnter(event) {
      if (!this.disabled) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    onDragOver() {
      if (!this.disabled) {
        DomHandler.addClass(this.$refs.content, 'fileupload-highlight');
        event.stopPropagation();
        event.preventDefault();
      }
    },
    onDragLeave() {
      if (!this.disabled) {
        DomHandler.removeClass(this.$refs.content, 'fileupload-highlight');
      }
    },
    onDrop() {
      if (!this.disabled) {
        DomHandler.removeClass(this.$refs.content, 'fileupload-highlight');
        event.stopPropagation();
        event.preventDefault();

        const files = event.dataTransfer
          ? event.dataTransfer.files
          : event.target.files;
        const allowDrop = this.multiple || (files && files.length === 1);

        if (allowDrop) {
          this.onFileSelect(event);
        }
      }
    },
    onBasicUploaderClick() {
      if (this.hasFiles) this.upload();
      else this.$refs.fileInput.click();
    },
    remove(index) {
      this.clearInputElement();
      this.files.splice(index, 1);
      this.files = [...this.files];
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    clearInputElement() {
      this.$refs.fileInput.value = '';
    },
    clearIEInput() {
      if (this.$refs.fileInput) {
        this.duplicateIEEvent = true; //IE11 fix to prevent onFileChange trigger again
        this.$refs.fileInput.value = '';
      }
    },
    formatSize(bytes) {
      if (bytes === 0) {
        return '0 B';
      }
      let k = 1000,
        dm = 3,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    isFileLimitExceeded() {
      if (
        this.fileLimit &&
        this.fileLimit <= this.files.length + this.uploadedFileCount &&
        this.focused
      ) {
        this.focused = false;
      }

      return (
        this.fileLimit &&
        this.fileLimit < this.files.length + this.uploadedFileCount
      );
    },
    checkFileLimit() {
      if (this.isFileLimitExceeded()) {
        this.messages.push(
          this.invalidFileLimitMessage.replace('{0}', this.fileLimit.toString())
        );
      }
    }
  }
};
</script>

<style>
.fileupload .fileupload-buttonbar {
  background: #f8f9fa;
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom: 0 none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.fileupload .fileupload-buttonbar button {
  margin-right: 0.5rem;
}
.fileupload .fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.fileupload .progressbar {
  height: 0.25rem;
}
.fileupload .fileupload-row > div {
  padding: 1rem 1rem;
}
.fileupload.fileupload-advanced .message {
  margin-top: 0;
}

.fileupload-content {
  position: relative;
}

.fileupload-row {
  display: flex;
  align-items: center;
}

.fileupload-row > div {
  flex: 1 1 auto;
  width: 25%;
}

.fileupload-row > div:last-child {
  text-align: right;
}

.fileupload-content .progressbar {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.fileupload-choose {
  position: relative;
  overflow: hidden;
}

.fileupload-choose input[type='file'] {
  display: none;
}

.fileupload-choose.fileupload-choose-selected input[type='file'] {
  display: none;
}

.fluid .fileupload .button {
  width: auto;
}
</style>