import http from '@/services/http';

const state = {
  streams: [],
  loaded: false
};

const getters = {
  conference: (state, getters, rootState, rootGetters) =>
    rootGetters['conferences/current'],
};

const actions = {
  async all({ commit, getters }, now) {
    let {
      data: { data }
    } = await http.get(`conferences/${getters.conference}/streams?d=${now}`);

    commit('SET_STREAMS', data);

    return data;
  },
  async signature({ getters }, meeting) {
    try {
      const { data: { data } } = await http.post(`conferences/${getters.conference}/streams/${meeting}/signature`);
      
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

const mutations = {
  SET_STREAMS(state, rows) {
    state.streams = rows
    state.loaded = true;
  },
  CLEAR_STREAMS(state) {
    state.streams = []
    state.loaded = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
