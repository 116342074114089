<template>
  <form
    @submit.prevent="onSubmit"
    @keypress.enter="onSubmit"
  >
    <slot />
  </form>
</template>

<script>
export default {
  name: 'AForm',
  emits: ['submit'],
  methods: {
    onSubmit() {
      this.$emit('submit');
    }
  }
};
</script>