<script>
export default {
  name: 'ATab',
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isActive() {
      return this.$parent.active === this.name
    },
    formattedTitle() {
      return this.title
    }
  },
  mounted() {
    this.$parent.addTab(this)
  },
  unmounted() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    this.$parent.removeTab(this)
  }
}
</script>

<template>
  <div v-if="isActive">
    <slot />
  </div>
</template>
