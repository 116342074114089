import { trimStart } from '@/utils/lodash';

export function resize(path, width, height, position, fit) {
  let json = {
    bucket: process.env.VUE_APP_AWS_IMAGE_BUCKET,
    key: trimStart(path, '/'),
    edits: {
      resize: {
        width: width,
        height: height,
        fit: fit || 'contain',
        position: position || 'top',
        background: '#fff'
      }
    }
  };

  let encoded = btoa(JSON.stringify(json));
  return process.env.VUE_APP_RESIZE_URL + `/${encoded}`;
}
