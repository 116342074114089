<template>
  <a-banner>
    <template #icon>
      <a-icon
        name="user-secret"
        class="text-white"
      />
    </template>
    <template #action>
      <button
        v-if="canStop"
        class="flex items-center justify-center w-full px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
        @click="onStop"
      >
        Stop & Sign-Out
      </button>
    </template>
    <template #mobile>
      Impersonating {{ name }}
    </template>
    You are Currently Impersonating {{ name }}
  </a-banner>
</template>

<script>
import ABanner from '@/components/ui/banner.vue';
import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from '@/utils/lodash';
export default {
  components: {
    ABanner
  },
  computed: {
    ...mapGetters('auth', ['name']),
    ...mapGetters('conferences', ['current']),
    canStop() {
      return !isEmpty(this.current);
    }
  },
  methods: {
    ...mapActions('impersonate', ['stopImpersonating']),
    async onStop() {
      await this.stopImpersonating();
      this.$router.push({ name: 'auth', params: { conference: this.current } });
    }
  }
};
</script>