<template>
  <button
    :type="type"
    :class="buttonClasses"
    :disabled="loading || disabled"
    v-bind="$attrs"
  >
    <a-icon
      v-if="loading"
      name="circle-notch"
      type="fad"
      size="lg"
      class="mr-1"
      spin
    />
    <slot />
  </button>
</template>

<script>
import variant from '@/mixins/variant.js';
import size from '@/mixins/size.js';
export default {
  name: 'AButton',
  mixins: [variant, size],
  props: {
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClasses() {
      return [
        'button',
        `button--${this.variant}`,
        `button--size--${this.size}`,
        this.wide ? `button--wide` : null,
        this.disabled ? `button--disabled` : null,
        this.inactive ? `button--inactive` : null,
        this.loading ? `button--loading` : null
      ];
    }
  }
};
</script>