import http from '@/services/http';
import dayjs, { format } from '@/utils/dates';
import { isEmpty, groupBy, find, size, get, map, each, head } from '@/utils/lodash';

const state = {
  schedule: null
};

const getters = {
  loaded: state => state.schedule !== null,
  find: state => id => find(state.schedule, row => row.id == id),
  conference: (state, getters, rootState, rootGetters) =>
    rootGetters['conferences/current'],
  total: state => size(state.schedule),
    subscribed: state => id => find(state.schedule, meeting => meeting.id === id),
  todaysSchedule: (state, getters) => {
    let today = dayjs().format('YYYY-MM-DD');

    let schedule = find(getters.groupedMeetings, (i, day) => day == today);

    if (isEmpty(schedule)) {
      return {};
    }

    return { [today]: schedule };
  },
  hasMeetingsToday: (state, getters) => !isEmpty(getters.todaysSchedule),
  groupedMeetings: state => {
    let dates = groupBy(state.schedule, schedule => format(schedule.slot.start, 'YYYY-MM-DD'));
    let days = {};
    each(dates, dates => {
      days[format(head(map(dates, 'slot.start')), 'YYYY-MM-DD')] = dates;
    })
    return days;
  },
  countByType: (state, getters) => type => {
    return size(get(getters.groupedType, type))
  },
  groupedType: state => {
    return groupBy(state.schedule, schedule => schedule.type.key);
  },
  scheduleDates: (state, getters) => {
    return Object.keys(getters.groupedMeetings)
  }
};

const actions = {
  async all({ commit, getters }) {
    let {
      data: { data }
    } = await http.get(`conferences/${getters.conference}/schedule`);

    commit('SET_SHOW_SCHEDULE', data);

    return data;
  },
  async subscribe({ getters }, {type, id}) {
    try {
      const { data: { data } } = await http.post(`conferences/${getters.conference}/${type}/${id}/subscribe`)

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async unsubscribe({ getters }, {type, id}) {
    try {
      const { data: { data } } = await http.post(`conferences/${getters.conference}/${type}/${id}/unsubscribe`)

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
};

const mutations = {
  SET_SHOW_SCHEDULE(state, rows) {
    state.schedule = rows;
  },
  CLEAR_SHOW_SCHEDULE(state) {
    state.schedule = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
