export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: value => {
        return (
          ['', 'primary', 'secondary', 'success', 'danger', 'white', 'gray'].indexOf(value) !==
          -1
        );
      }
    }
  }
};
