<template>
  <div class="accordion">
    <div
      v-for="(tab,i) of tabs"
      :key="getKey(tab,i)"
      :class="getTabClass(i)"
    >
      <div :class="getTabHeaderClass(tab, i)">
        <a
          :id="getTabAriaId(i) + '_header'"
          role="tab"
          class="accordion__tab__header__link"
          :tabindex="isTabDisabled(tab) ? null : '0'"
          :aria-expanded="isTabActive(i)"
          :aria-controls="getTabAriaId(i) + '_content'"
          @click="onTabClick($event, tab, i)"
          @keydown="onTabKeydown($event, tab, i)"
        >
          <span :class="getHeaderIcon(i)" />
          <span
            v-if="tab.props?.header"
            class="accordion__tab__header__text"
          >{{ tab.props?.header }}</span>
          <component
            :is="tab.children.header"
            v-if="tab.children?.header"
          />
        </a>
      </div>
      <div
        v-if="isTabActive(i)"
        :id="getTabAriaId(i) + '_content'"
        class="fade"
        role="region"
        :aria-labelledby="getTabAriaId(i) + '_header'"
      >
        <div class="accordion__tab__content">
          <component :is="tab" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqueId } from '@/utils/lodash'
export default {
    name: 'AAccordion',
    props: {
        multiple: {
          type: Boolean,
          default: false
        },
        active: {
          type: [Number, Array],
          default: null
        }
    },
    emits: ['tab-close', 'tab-open', 'update:active'],
    data() {
        return {
            internalActiveIndex: this.active
        }
    },
    computed: {
        lastIndex() {
          return this.tabs.indexOf(this.tabs.slice(-1)[0]);
        },
        tabs() {
            const tabs = []
            this.$slots.default().forEach(child => {
                    if (this.isAccordionTab(child)) {
                        tabs.push(child);
                    }
                    else if (child.children.length > 0) {
                        child.children.forEach(nestedChild => {
                            if (this.isAccordionTab(nestedChild)) {
                                tabs.push(nestedChild)
                            }
                        });
                    }
                }
            )
            return tabs;
        },
        ariaId() {
            return uniqueId();
        }
    },
    watch: {
        active() {
            this.internalActiveIndex = this.active;
        }
    },
    methods: {
        onTabClick(event, tab, i) {
            if (!this.isTabDisabled(tab)) {
                const active = this.isTabActive(i);
                const eventName = active ? 'tab-close' : 'tab-open';
                if (this.multiple) {
                    if (active) {
                        this.internalActiveIndex = this.internalActiveIndex.filter(index => index !== i);
                    }
                    else {
                        if (this.internalActiveIndex)
                            this.internalActiveIndex.push(i);
                        else
                            this.internalActiveIndex = [i];
                    }
                }
                else {
                    this.internalActiveIndex = this.internalActiveIndex === i ? null : i;
                }
                this.$emit('update:active', this.internalActiveIndex);
                this.$emit(eventName, {
                    originalEvent: event,
                    index: i
                });
            }
        },
        onTabKeydown(event, tab, i) {
            if (event.which === 13) {
                this.onTabClick(event, i);
            }
        },
        isTabActive(index) {
            if (this.multiple)
                return this.internalActiveIndex && this.internalActiveIndex.includes(index);
            else
                return index === this.internalActiveIndex;
        },
        getKey(tab, i) {
            return tab.props?.header ? tab.props.header : i;
        },
        isTabDisabled(tab) {
            return tab.props?.disabled;
        },
        getTabClass(i) {
            return ['accordion__tab', {'accordion__tab--active': this.isTabActive(i)}];
        },
        getTabHeaderClass(tab, i) {
            return [
              'accordion__tab__header',
              {
                'accordion__tab__header--first': i === 0,
                'accordion__tab__header--last': i === this.lastIndex,
                'accordion__tab__header--active': this.isTabActive(i),
                'accordion__tab__header--disabled': this.isTabDisabled(tab)
              }
            ];
        },
        getTabAriaId(i) {
            return this.ariaId + '_' + i;
        },
        getHeaderIcon(i) {
            const active = this.isTabActive(i);
            return ['accordion__icon', {'chevron-right': !active, 'chevron-down': active}];
        },
        isAccordionTab(child) {
            return child.type.name === 'AAccordionTab'
        }
    }
}
</script>