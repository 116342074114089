<template>
  <div class="h-screen flex overflow-hidden bg-cool-gray-100">
    <mobile-menu
      :open="mobileMenuOpen"
      @close="mobileMenuOpen = false"
    />
    <desktop-menu />

    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative z-20 flex-shrink-0 flex h-20 bg-white shadow">
        <mobile-menu-toggle @click="mobileMenuOpen = true" />
        <!-- Search bar -->
        <div
          class="flex-1 px-4 flex justify-end sm:px-6 lg:max-w-full lg:mx-auto lg:px-8"
        >
          <div class="ml-4 flex items-center md:ml-6">
            <profile-menu />
          </div>
        </div>
      </div>
      <main
        class="flex-1 relative overflow-y-auto focus:outline-none"
        tabindex="0"
      >
        <div class="pt-2 pb-6 md:py-6">
          <div class="w-full mx-auto px-2 sm:px-4">
            <slot />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MobileMenu from '@/components/layout/mobile/menu.vue';
import MobileMenuToggle from '@/components/layout/mobile/toggle.vue';
import DesktopMenu from '@/components/layout/desktop/menu.vue';
import ProfileMenu from '@/components/layout/header/profile-menu.vue';
export default {
  components: {
    MobileMenu,
    DesktopMenu,
    ProfileMenu,
    MobileMenuToggle
  },
  data: () => ({
    mobileMenuOpen: false
  })
};
</script>