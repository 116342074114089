import Base from '@/views/base.vue';
import DashboardBase from '@/views/dashboard/base.vue';
import NotFound from '@/views/errors/not-found.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import(
        /* webpackChunkName: "home" */
        '@/views/home.vue'
      ),
    meta: {
      guest: true
    }
  },
  {
    path: '/c/:conference',
    props: true,
    component: Base,
    children: [
      {
        path: '',
        component: DashboardBase,
        props: true,
        children: [
          {
            path: '',
            name: 'dashboard',
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */
                '@/views/dashboard/home.vue'
              )
          }
        ]
      },
      {
        path: 'my-schedule',
        component: DashboardBase,
        children: [
          {
            path: '',
            name: 'my.schedule',
            component: () =>
              import(
                /* webpackChunkName: "my-schedule" */
                '@/views/dashboard/schedule/my.vue'
              )
          }
        ]
      },
      {
        path: 'schedule',
        component: DashboardBase,
        children: [
          {
            path: '',
            name: 'schedule.index',
            component: () =>
              import(
                /* webpackChunkName: "schedule" */
                '@/views/dashboard/schedule/index.vue'
              )
          },
          {
            path: 'recordings',
            component: () =>
              import(
                /* webpackChunkName: "schedule" */
                '@/views/dashboard/schedule/recordings/base.vue'
              ),
            props: true,
            children: [
              {
                path: '',
                props: true,
                name: 'schedule.recordings.index',
                component: () =>
                  import(
                    /* webpackChunkName: "schedule" */
                    '@/views/dashboard/schedule/recordings/index.vue'
                  )
              },
              {
                path: ':meeting',
                props: true,
                name: 'schedule.recordings.view',
                component: () =>
                  import(
                    /* webpackChunkName: "schedule" */
                    '@/views/dashboard/schedule/recordings/video.vue'
                  )
              },
            ]
          }
        ]
      },
      {
        path: 'exhibitors',
        component: DashboardBase,
        children: [
          {
            path: '',
            component: () =>
                  import(
                    /* webpackChunkName: "exhibitors" */
                    '@/views/dashboard/exhibitors/base.vue'
                  ),
            children: [
              {
                path: '',
                name: 'virtual.exhibitors',
                component: () =>
                  import(
                    /* webpackChunkName: "exhibitors" */
                    '@/views/dashboard/exhibitors/index.vue'
                  )
              },
              {
                path: ':slug',
                component: () =>
                      import(
                        /* webpackChunkName: "exhibitors" */
                        '@/views/dashboard/exhibitors/view-base.vue'
                      ),
                props: true,
                children: [
                  {
                    path: '',
                    props: true,
                    name: 'virtual.exhibitors.view',
                    component: () =>
                      import(
                        /* webpackChunkName: "exhibitors" */
                        '@/views/dashboard/exhibitors/view.vue'
                      )
                  },
                ]
              }
            ]
          },
        ]
      },
      {
        path: 'booth',
        component: DashboardBase,
        children: [
          {
            path: '',
            component: () =>
              import(
                /* webpackChunkName: "booth" */
                '@/views/dashboard/exhibitors/booth/base.vue'
              ),
              children: [
                {
                  path: '',
                  name: 'virtual.booth',
                  component: () =>
                    import(
                      /* webpackChunkName: "booth" */
                      '@/views/dashboard/exhibitors/booth/view.vue'
                    )
                },
                {
                  path: 'manage',
                  name: 'virtual.booth.manage',
                  component: () =>
                    import(
                      /* webpackChunkName: "booth" */
                      '@/views/dashboard/exhibitors/booth/manage.vue'
                    )
                }
              ]
          },
        ]
      },
      {
        path: 'seminars',
        component: DashboardBase,
        children: [
          {
            path: '',
            name: 'seminars.index',
            component: () =>
              import(
                /* webpackChunkName: "seminars" */
                '@/views/dashboard/seminars/index.vue'
              )
          },
          {
            path: 'recordings',
            component: () =>
              import(
                /* webpackChunkName: "seminars" */
                '@/views/dashboard/seminars/recordings/base.vue'
              ),
            props: true,
            children: [
              {
                path: '',
                props: true,
                name: 'seminars.recordings.index',
                component: () =>
                  import(
                    /* webpackChunkName: "seminars" */
                    '@/views/dashboard/seminars/recordings/index.vue'
                  )
              },
              {
                path: ':meeting',
                props: true,
                name: 'seminars.recordings.view',
                component: () =>
                  import(
                    /* webpackChunkName: "seminars" */
                    '@/views/dashboard/seminars/recordings/video.vue'
                  )
              },
            ]
          }
        ]
      },
      {
        path: 'streaming',
        component: DashboardBase,
        children: [
          {
            path: '',
            name: 'streaming.index',
            component: () =>
              import(
                /* webpackChunkName: "streaming" */
                '@/views/dashboard/streaming/index.vue'
              )
          }
        ]
      },
      {
        path: 'attendees',
        component: DashboardBase,
        children: [
          {
            path: '',
            name: 'attendees.index',
            meta: {
              exhibitor: true
            },
            component: () =>
              import(
                /* webpackChunkName: "attendees" */
                '@/views/dashboard/attendees/index.vue'
              )
          }
        ]
      },
      {
        path: 'i/:uuid',
        name: 'impersonate',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "impersonate" */
            '@/views/impersonate/impersonate.vue'
          ),
        meta: {
          guest: true
        }
      },
      {
        path: 'm/:uuid',
        name: 'magic.link',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "magic-link" */
            '@/views/magic-link/index.vue'
          ),
        meta: {
          guest: true
        }
      },
      {
        path: 'auth',
        name: 'auth',
        component: () =>
          import(
            /* webpackChunkName: "auth" */
            '@/views/auth/auth.vue'
          ),
        meta: {
          guest: true
        }
      },
      {
        path: 'reset-password',
        name: 'reset.password',
        component: () =>
          import(
            /* webpackChunkName: "reset.password" */
            '@/views/auth/reset.vue'
          ),
        meta: {
          guest: true
        }
      },
      {
        path: 'reset-password/:token/:email',
        name: 'reset.password.token',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "reset.password-form" */
            '@/views/auth/reset-form.vue'
          ),
        meta: {
          guest: true
        }
      },
      {
        path: 'management',
        component: DashboardBase,
        children: [
          {
            path: '',
            name: 'management.index',
            component: () =>
              import(
                /* webpackChunkName: "management" */
                '@/views/dashboard/management/index.vue'
              ),
            meta: {
              manager: true
            }
          },
          {
            path: 'attendees',
            component: () =>
              import(
                /* webpackChunkName: "management" */
                '@/views/dashboard/management/base.vue'
              ),
            children: [
              {
                path: '',
                name: 'management.attendees',
                component: () =>
                  import(
                    /* webpackChunkName: "management-attendees" */
                    '@/views/dashboard/management/attendees/index.vue'
                  ),
                meta: {
                  manager: true
                }
              },
              {
                path: ':id/schedule',
                name: 'management.attendees.schedule',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "management-attendees" */
                    '@/views/dashboard/management/attendees/schedule.vue'
                  ),
                meta: {
                  manager: true
                }
              },
            ],
          },
        ]
      },
      {
        path: 'account',
        component: DashboardBase,
        children: [
          {
            path: '',
            name: 'account.details',
            component: () =>
              import(
                /* webpackChunkName: "account" */
                '@/views/dashboard/account/details.vue'
              )
          }
        ]
      },
    ]
  },
  {
    path: '/stream-ended',
    name: 'stream-ended',
    component: () =>
      import(
        /* webpackChunkName: "stream" */
        '@/views/stream/ended.vue'
      ),
    meta: {
      guest: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not.found',
    component: NotFound
  }
];

export default routes;
