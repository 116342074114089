const state = {
  title: 'Guide'
};

const getters = {};

const actions = {
  setTitle({ commit }, title) {
    commit('SET_GUIDE_TITLE', title)
  }
};

const mutations = {
  SET_GUIDE_TITLE(state, title) {
    state.title = title;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
