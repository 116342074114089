<template>
  <div class="md:hidden">
    <div
      class="fixed inset-0 flex z-40"
      :class="{ hidden: !open }"
    >
      <transition
        enter-active-class="transition-opacity ease-linear duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity ease-linear duration-300"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="open"
          class="fixed inset-0"
        >
          <div class="absolute inset-0 bg-gray-600 opacity-75" />
        </div>
      </transition>

      <transition
        enter-active-class="transition ease-in-out duration-300 transform"
        enter-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition ease-in-out duration-300 transform"
        leave-class="translate-x-0"
        leave-to-class="-translate-x-full"
      >
        <div
          v-if="open"
          class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white"
        >
          <div class="absolute top-0 right-0 -mr-14 p-1">
            <button
              class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
              aria-label="Close sidebar"
              @click="onClose"
            >
              <svg
                class="h-6 w-6 text-white"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="flex-shrink-0 flex justify-center px-4">
            <logo class="max-h-32 w-auto" />
          </div>
          <div class="mt-5 flex-1 h-0 overflow-y-auto">
            <navigation
              class="px-2 space-y-1"
              @click="onClose"
            />
          </div>
        </div>
      </transition>
      <div class="flex-shrink-0 w-14">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/layout/header/logo.vue';
import Navigation from '@/components/layout/navigation.vue';
export default {
  components: {
    Logo,
    Navigation
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  methods: {
    onClose() {
      this.$emit('close');
    }
  }
};
</script>