import { uniqueId } from "@/utils/lodash";
import Dom from "@/utils/dom";
import ScrollHandler from "@/utils/scroll";

function bindEvents(el) {
    const modifiers = el.$_tooltipModifiers;
    if (modifiers.focus) {
        el.addEventListener('focus', onFocus);
        el.addEventListener('blur', onBlur);
    }
    else {
        el.addEventListener('mouseenter', onMouseEnter);
        el.addEventListener('mouseleave', onMouseLeave);
        el.addEventListener('click', onClick);
    }
}

function unbindEvents(el) {
    const modifiers = el.$_tooltipModifiers;
    if (modifiers.focus) {
        el.removeEventListener('focus', onFocus);
        el.removeEventListener('blur', onBlur);
    }
    else {
        el.removeEventListener('mouseenter', onMouseEnter);
        el.removeEventListener('mouseleave', onMouseLeave);
        el.removeEventListener('click', onClick);
    }
}

function bindScrollListener(el) {
    if (!el.$_tooltipScrollHandler) {
        el.$_tooltipScrollHandler = new ScrollHandler(el, function() {
            hide(el);
        });
    }

    el.$_tooltipScrollHandler.bindScrollListener();
}

function unbindScrollListener(el) {
    if (el.$_tooltipScrollHandler) {
        el.$_tooltipScrollHandler.unbindScrollListener();
    }
}

function onMouseEnter(event) {
    show(event.currentTarget);
}

function onMouseLeave(event) {
    hide(event.currentTarget);
}

function onFocus(event) {
    show(event.currentTarget);
}

function onBlur(event) {
    hide(event.currentTarget);
}

function onClick(event) {
    hide(event.currentTarget);
}

function show(el) {
    if (!el.$_tooltipValue) {
        return;
    }

    let tooltipElement = create(el);
    align(el);
    Dom.fadeIn(tooltipElement, 250);
    tooltipElement.style.zIndex = ++Dom.zindex;

    window.addEventListener('resize', function onWindowResize() {
        hide(el);
        this.removeEventListener('resize', onWindowResize);
    });

    bindScrollListener(el);
}

function hide(el) {
    remove(el);
    unbindScrollListener(el);
}

function getTooltipElement(el) {
    return document.getElementById(el.$_tooltipId);
}

function create(el) {
    const id = uniqueId() + '_tooltip';
    el.$_tooltipId = id;

    let container = document.createElement('div');
    container.id = id;

    let tooltipArrow = document.createElement('div');
    tooltipArrow.className = 'tooltip-arrow';
    container.appendChild(tooltipArrow);

    let tooltipText = document.createElement('div');
    tooltipText.className = 'tooltip-text';
    tooltipText.innerHTML = el.$_tooltipValue;

    container.appendChild(tooltipText);
    document.body.appendChild(container);

    container.style.display = 'inline-block';

    return container;
}

function remove(el) {
    if (el) {
        let tooltipElement = getTooltipElement(el);
        if (tooltipElement && tooltipElement.parentElement) {
            document.body.removeChild(tooltipElement);
        }
        el.$_tooltipId = null;
    }
}

function align(el) {
    const modifiers = el.$_tooltipModifiers;

    if (modifiers.top) {
        alignTop(el);
        if (isOutOfBounds(el)) {
            alignBottom(el);
        }
    }
    else if (modifiers.left) {
        alignLeft(el);
        if (isOutOfBounds(el)) {
            alignRight(el);

            if (isOutOfBounds(el)) {
                alignTop(el);

                if (isOutOfBounds(el)) {
                    alignBottom(el);
                }
            }
        }
    }
    else if (modifiers.bottom) {
        alignBottom(el);
        if (isOutOfBounds(el)) {
            alignTop(el);
        }
    }
    else {
        alignRight(el);
        if (isOutOfBounds(el)) {
            alignLeft(el);

            if (isOutOfBounds(el)) {
                alignTop(el);

                if (isOutOfBounds(el)) {
                    alignBottom(el);
                }
            }
        }
    }
}

function getHostOffset(el) {
    let offset = el.getBoundingClientRect();
    let targetLeft = offset.left + Dom.getWindowScrollLeft();
    let targetTop = offset.top + Dom.getWindowScrollTop();

    return {left: targetLeft, top: targetTop};
}

function alignRight(el) {
    preAlign(el, 'right');
    let tooltipElement = getTooltipElement(el);
    let hostOffset = getHostOffset(el);
    let left = hostOffset.left + Dom.getOuterWidth(el);
    let top = hostOffset.top + (Dom.getOuterHeight(el) - Dom.getOuterHeight(tooltipElement)) / 2;
    tooltipElement.style.left = left + 'px';
    tooltipElement.style.top = top + 'px';
}

function alignLeft(el) {
    preAlign(el, 'left');
    let tooltipElement = getTooltipElement(el);
    let hostOffset = getHostOffset(el);
    let left = hostOffset.left - Dom.getOuterWidth(tooltipElement);
    let top = hostOffset.top + (Dom.getOuterHeight(el) - Dom.getOuterHeight(tooltipElement)) / 2;
    tooltipElement.style.left = left + 'px';
    tooltipElement.style.top = top + 'px';
}

function alignTop(el) {
    preAlign(el, 'top');
    let tooltipElement = getTooltipElement(el);
    let hostOffset = getHostOffset(el);
    let left = hostOffset.left + (Dom.getOuterWidth(el) - Dom.getOuterWidth(tooltipElement)) / 2;
    let top = hostOffset.top - Dom.getOuterHeight(tooltipElement);
    tooltipElement.style.left = left + 'px';
    tooltipElement.style.top = top + 'px';
}

function alignBottom(el) {
    preAlign(el, 'bottom');
    let tooltipElement = getTooltipElement(el);
    let hostOffset = getHostOffset(el);
    let left = hostOffset.left + (Dom.getOuterWidth(el) - Dom.getOuterWidth(tooltipElement)) / 2;
    let top = hostOffset.top + Dom.getOuterHeight(el);
    tooltipElement.style.left = left + 'px';
    tooltipElement.style.top = top + 'px';
}

function preAlign(el, position) {
    let tooltipElement = getTooltipElement(el);
    tooltipElement.style.left = -999 + 'px';
    tooltipElement.style.top = -999 + 'px';
    tooltipElement.className = 'tooltip tooltip-' + position;
}

function isOutOfBounds(el) {
    let tooltipElement = getTooltipElement(el);
    let offset = tooltipElement.getBoundingClientRect();
    let targetTop = offset.top;
    let targetLeft = offset.left;
    let width = Dom.getOuterWidth(tooltipElement);
    let height = Dom.getOuterHeight(tooltipElement);
    let viewport = Dom.getViewport();

    return (targetLeft + width > viewport.width) || (targetLeft < 0) || (targetTop < 0) || (targetTop + height > viewport.height);
}

const Tooltip = {
    beforeMount(el, options) {
        el.$_tooltipModifiers = options.modifiers;
        el.$_tooltipValue = options.value;
        bindEvents(el);
    },
    unmounted(el) {
        remove(el);
        unbindEvents(el);

        if (el.$_tooltipScrollHandler) {
            el.$_tooltipScrollHandler.destroy();
            el.$_tooltipScrollHandler = null;
        }
    },
    updated(el, options) {
        el.$_tooltipModifiers = options.modifiers;
        el.$_tooltipValue = options.value;
    }
};

export default Tooltip;