import { has } from '@/utils/lodash'
export default {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    modelValue: [
      String,
      Boolean,
      Object,
      Number,
      Array
    ],
    value: {
      type: [String, Boolean, Object, Number]
    },
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    isCheckedOrSelected () {
      if (this.isModelArray) {
        return this.modelValue.includes(this.value)
      }

      if (this.hasValue) {
        return this.modelValue === this.value
      }

      return this.modelValue === this.trueValue
    },
    hasValue () {
      return has(this.$options.propsData, 'value')
    },
    isModelArray () {
      return Array.isArray(this.modelValue)
    }
  },
  methods: {
    removeItemFromModel (newModel) {
      const index = newModel.indexOf(this.value)

      if (index !== -1) {
        newModel.splice(index, 1)
      }
    },
    handleArrayCheckbox () {
      const newModel = this.modelValue

      if (!this.isCheckedOrSelected) {
        newModel.push(this.value)
      } else {
        this.removeItemFromModel(newModel)
      }

      this.$emit('update:modelValue', newModel)
    },
    handleSingleSelectCheckbox () {
      this.$emit('update:modelValue', this.isCheckedOrSelected ? null : this.value)
    },
    handleSimpleCheckbox () {
      this.$emit('update:modelValue', this.isCheckedOrSelected ? this.falseValue : this.trueValue)
    },
    onToggleCheck () {
      if (!this.disabled) {
        if (this.isModelArray) {
          this.handleArrayCheckbox()
        } else if (this.hasValue) {
          this.handleSingleSelectCheckbox()
        } else {
          this.handleSimpleCheckbox()
        }
      }
    }
  }
}
