<template>
  <div
    :class="notificationClasses"
    @click="onClick"
    @mouseover="onHover"
    @mouseleave="onBlur"
  >
    <slot>
      <div class="rounded-lg shadow-xs overflow-hidden">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <slot name="icon">
                <a-icon
                  v-if="icon"
                  :name="icon"
                  size="lg"
                  variant
                />
              </slot>
            </div>
            <slot name="content">
              <div class="ml-3 w-0 flex-1">
                <p
                  v-if="notification.title"
                  :title="notification.title"
                  :class="['text-sm leading-5 font-medium']"
                  v-text="notification.title"
                />
                <p
                  v-if="notification.text"
                  :title="notification.text"
                  :class="['text-sm leading-5 font-medium', { 'mt-1': notification.title, truncate: !expanded }]"
                  v-text="notification.text"
                />
                <p
                  v-else-if="notification.html"
                  :title="notification.text"
                  :class="['text-sm leading-5 font-medium', { 'mt-1': notification.title, truncate: !expanded }]"
                  v-html="notification.html"
                />
              </div>
            </slot>
            <slot name="close">
              <div
                v-if="notification.dismissible"
                class="ml-4 flex-shrink-0 flex"
              >
                <a-button
                  variant
                  narrow
                  class="inline-flex focus:outline-none focus:text-gray-500 transition ease-in-out duration-150 p-0"
                  @click="onClose"
                >
                  <a-icon
                    name="times"
                    variant
                    size="lg"
                  />
                </a-button>
              </div>
            </slot>
          </div>
        </div>
        <div
          v-if="hasTimer"
          :class="['notification__timer', `notification__timer--${variant}`]"
          :style="{ width: timerWidth + '%' }"
        />
      </div>
    </slot>
  </div>
</template>

<script>
import variant from '@/mixins/variant';
export default {
  mixins: [variant],
  props: {
    notification: {
      type: Object,
      required: true
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    pauseOnHover: {
      type: Boolean,
      default: true
    },
    expand: {
      type: [Boolean, String],
      default: false
    }
  },
  emits: ['hover', 'click', 'blur', 'close', 'time'],
  data: () => ({
    hovered: false,
    clicked: false,
    timer: null,
    timerWidth: 100
  }),
  computed: {
    expanded() {
      return (
        this.expand === true ||
        (this.expand === 'hover' && this.hovered) ||
        (this.expand === 'click' && this.clicked)
      );
    },
    isPointer() {
      return this.expand === 'click';
    },
    icon() {
      if (this.notification.icon !== undefined) {
        return this.notification.icon;
      }

      return this.guessIcon;
    },
    guessIcon() {
      switch (this.variant) {
        case 'success':
          return 'check-circle';
        case 'danger':
          return 'times-circle';
      }

      return null;
    },
    hasTimer() {
      return (
        this.notification.duration !== Infinity &&
        this.notification.duration > 0
      );
    },
    notificationClasses() {
      return [
        'notification',
        `notification--${this.variant}`,
        {
          'notification--dismissible': this.closeOnClick,
          'cursor-pointer': this.isPointer
        }
      ];
    }
  },
  watch: {
    timerWidth() {
      if (this.timerWidth < 0) {
        this.onTimerEnded();
      }
    }
  },
  created() {
    if (this.hasTimer) {
      this.startTimer();
    }
  },
  beforeUnmount() {
    if (this.timer !== null) {
      this.stopTimer();
    }
  },
  methods: {
    startTimer() {
      this.timer = setInterval(async () => {
        this.timerWidth -= 1;
      }, this.notification.duration / 100);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    onTimerEnded() {
      this.onCallback('onTime');
      this.$emit('time', this.notification);
      this.onClose();
    },
    onClose() {
      this.onCallback('onClose');
      this.$emit('close', this.notification);
    },
    onClick() {
      this.clicked = !this.clicked;
      this.onCallback('onClick');
      this.$emit('click', this.notification);
    },
    onHover() {
      this.hovered = true;
      this.onCallback('onHover');
      this.$emit('hover', this.notification);
      if (this.hasTimer && this.pauseOnHover) {
        this.stopTimer();
      }
    },
    onBlur() {
      this.hovered = false;
      this.onCallback('onBlur');
      this.$emit('blur', this.notification);
      if (this.hasTimer && this.pauseOnHover) {
        this.startTimer();
      }
    },
    onCallback(type) {
      if (typeof this.notification[type] === 'function') {
        this.notification[type](this.notification);
      }
    }
  }
};
</script>
