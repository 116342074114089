import { has, get, join, toArray, indexOf, find } from "./lodash";

export function replace(collection, identity, replacement) {
  var index = indexOf(collection, find(collection, identity));
  collection.splice(index, 1, replacement);
}


export const uniqueId = length => {
  let string = Math.random().toString(36).substr(2, 11);

  if(length === undefined) {
    return string;
  }

  return string.substring(0, length);
}

export function error(e) {
  if(has(e, 'response.data.errors')) {
    return join(toArray(get(e, 'response.data.errors')), "<br>");
  } else if(has(e, 'response.data.message')) {
    return e.response.data.message;
  } else if(has(e, 'message')) {
    return e.message;
  }
}

export function currency(price) {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });

  return formatter.format(price.replace(',', ''))
}

export function number(number) {
  const formatter = new Intl.NumberFormat(navigator.language);

  return formatter.format(number)
}