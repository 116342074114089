<template>
  <dashboard-layout>
    <router-view
      v-if="current"
      :key="$route.path"
    />
    <impersonate-banner v-if="isImpersonating" />
  </dashboard-layout>
</template>

<script>
import ImpersonateBanner from '@/components/dashboard/impersonate/banner.vue';
import DashboardLayout from '@/layouts/dashboard.vue';
import { mapActions, mapGetters } from 'vuex';
import { get } from '@/utils/lodash';
export default {
  components: {
    ImpersonateBanner,
    DashboardLayout
  },
  data: () => ({
    scheduleInterval: null
  }),
  computed: {
    ...mapGetters('management', ['isManager']),
    ...mapGetters('impersonate', ['isImpersonating']),
    ...mapGetters('auth', ['isUser', 'user', 'hasToken', 'isExhibitor']),
    ...mapGetters('conferences', [
      'conferences',
      'has',
      'current',
      'conference'
    ]),
    requiresManager() {
      return get(this.$route.meta, 'manager');
    },
    requiresExhibitor() {
      return get(this.$route.meta, 'exhibitor');
    }
  },
  watch: {
    current: {
      immediate: true,
      handler: 'onCurrentChange'
    },
    user() {
      if (this.isUser) {
        this.onUserLoaded();
      }
    }
  },
  created() {
    this.setupScheduleInterval();
  },
  beforeUnmount() {
    this.clearSchedule();
    this.clearScheduleInterval();
  },
  methods: {
    ...mapActions('auth', ['me', 'clearAuth']),
    ...mapActions('conferences', { getConference: 'get' }),
    ...mapActions('user', {
      userConferences: 'conferences',
      fetchSchedule: 'fetchSchedule',
      clearSchedule: 'clearSchedule'
    }),
    setupScheduleInterval() {
      this.scheduleInterval = setInterval(() => this.fetchSchedule(), 10000);
    },
    clearScheduleInterval() {
      clearInterval(this.scheduleInterval);
    },
    async onCurrentChange() {
      this.onVerifyUser();
      this.getConference(this.current);
      this.fetchSchedule();
    },
    async onUserLoaded() {
      await this.userConferences();

      // Verify management screens
      this.verifyManagementScreen();
      // Verify attendees screen
      this.verifyAttendeesScreen();
    },
    verifyManagementScreen() {
      // Make sure it's not management route
      if (this.requiresManager && !this.isManager) {
        this.$router.push({
          name: 'dashboard',
          params: { current: this.current }
        });
        return;
      }
    },
    verifyAttendeesScreen() {
      if (this.requiresExhibitor && !this.isExhibitor) {
        this.$router.push({
          name: 'dashboard',
          params: { current: this.current }
        });
        return;
      }
    },
    async onVerifyUser() {
      if (!this.current) {
        return;
      }

      // If we have a token then verify that it's valid
      // make sure we wait until we have conferences loaded
      if (this.hasToken) {
        try {
          await this.me();
        } catch (e) {
          console.error(e);

          // Otherwise redirect to the current conference auth screen
          this.$router.push({
            name: 'auth',
            params: { conference: this.current }
          });
        }
      }
    }
  }
};
</script>