<template>
  <a
    role="menuitem"
    :class="{ current: isCurrent }"
    @click="onClick"
  >
    <logo
      :conference="conference"
      :width="200"
      :height="100"
      small
    />
  </a>
</template>

<script>
import Logo from '@/components/conferences/cards/logo.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Logo
  },
  props: {
    conference: {
      type: Object,
      required: true
    }
  },
  emits: ['click'],
  computed: {
    ...mapGetters('conferences', ['current']),
    isCurrent() {
      return this.current == this.conference.slug;
    }
  },
  methods: {
    onClick() {
      this.$emit('click');

      if (this.current == this.conference.slug) {
        return;
      }

      this.$router.push({
        name: this.$route.name,
        params: { conference: this.conference.slug }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  @apply cursor-pointer block px-4 py-2 text-base text-center text-cool-gray-700 hover:bg-cool-gray-100 transition ease-in-out duration-150;

  &.current {
    @apply cursor-not-allowed opacity-50;
  }
}
</style>