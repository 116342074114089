<template>
  <div :class="['alert', `alert--${type}`]">
    <div class="flex">
      <div
        v-if="icon"
        class="flex-shrink-0"
      >
        <slot name="icon">
          <a-icon
            type="fad"
            :name="icon"
            size="lg"
            :class="['alert__icon', `alert__icon--${type}`]"
          />
        </slot>
      </div>
      <div class="ml-3 flex-auto">
        <h3
          v-if="hasHeader"
          :class="['alert__header', `alert__header--${type}`]"
        >
          <slot name="header">
            {{ title }}
          </slot>
        </h3>
        <div :class="['alert__content', `alert__content--${type}`]">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AAlert',
  props: {
    type: {
      type: String,
      required: true,
      validator: value => {
        return ['success', 'warning', 'error', 'info'].indexOf(value) !== -1;
      }
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasHeader() {
      return this.title || !!this.$slots['header'];
    },
    icon() {
      switch (this.type) {
        case 'success':
          return 'check-circle';
        case 'warning':
          return 'exclamation-triangle';
        case 'error':
          return 'times-circle';
        case 'info':
          return 'info-circle';
      }

      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.alert {
  @apply rounded-md p-4 mb-2;

  &--success {
    @apply bg-green-50;
  }
  &--warning {
    @apply bg-yellow-50;
  }
  &--error {
    @apply bg-red-50;
  }
  &--info {
    @apply bg-blue-50;
  }

  &__header {
    @apply text-sm leading-5 font-medium mb-2;

    &--success {
      @apply text-green-800;
    }
    &--warning {
      @apply text-yellow-800;
    }
    &--error {
      @apply text-red-800;
    }
    &--info {
      @apply text-blue-800;
    }
  }

  &__content {
    @apply text-sm leading-5;

    &--success {
      @apply text-green-700;
    }
    &--warning {
      @apply text-yellow-700;
    }
    &--error {
      @apply text-red-700;
    }
    &--info {
      @apply text-blue-700;
    }
  }

  &__icon {
    &--success {
      @apply text-green-400;
    }
    &--warning {
      @apply text-yellow-400;
    }
    &--error {
      @apply text-red-400;
    }
    &--info {
      @apply text-blue-400;
    }
  }
}
</style>