<template>
  <div class="flex flex-col space-y-4">
    <div
      v-for="i in times"
      :key="i"
      class="animate-pulse flex space-x-2"
    >
      <div class="rounded-md bg-gray-400 h-7 w-7" />
      <div class="flex-1 py-1">
        <div class="h-6 bg-gray-400 rounded" />
      </div>
    </div>
  </div>
</template>

<script>
import { times } from '@/utils/lodash';
export default {
  props: {
    rows: {
      type: [Number, String],
      default: 1
    }
  },
  computed: {
    times() {
      return times(this.rows);
    }
  }
};
</script>