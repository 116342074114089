<template>
  <div class="md:flex md:items-center md:justify-between md:mb-4">
    <div class="flex-1 min-w-0">
      <h2
        class="text-2xl font-medium leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate"
      >
        <slot />
      </h2>
    </div>
    <div class="mt-4 md:mt-0 md:ml-4 mb-4 md:mb-0">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AHeading'
};
</script>