<template>
  <div
    ref="container"
    :class="containerClass"
    @click="onClick"
  >
    <div class="hidden-accessible">
      <input
        :id="inputId"
        ref="focusInput"
        type="text"
        role="listbox"
        readonly
        :disabled="disabled"
        :tabindex="tabindex"
        aria-haspopup="listbox"
        :aria-expanded="overlayVisible"
        :aria-labelledby="ariaLabelledBy"
        @focus="onFocus"
        @blur="onBlur"
        @keydown="onKeyDown"
      >
    </div>
    <div class="multiselect-label-container">
      <div :class="labelClass">
        <slot
          name="value"
          :value="modelValue"
          :placeholder="placeholder"
        >
          <template v-if="display === 'comma'">
            {{ label || 'empty' }}
          </template>
          <template v-else-if="display === 'chip'">
            <div
              v-for="item of modelValue"
              :key="getLabelByValue(item)"
              class="multiselect-token"
            >
              <span class="multiselect-token-label">{{ getLabelByValue(item) }}</span>
              <a-icon 
                v-if="!disabled"
                class="multiselect-token-icon"
                name="times-circle" 
                @click="removeChip(item)"
              />
            </div>
            <template v-if="!modelValue || modelValue.length === 0">
              {{ placeholder || 'empty' }}
            </template>
          </template>
        </slot>
      </div>
    </div>
    <div class="multiselect-trigger">
      <a-icon 
        class="multiselect-trigger-icon"
        name="chevron-down" 
      />
    </div>
    <transition
      name="connected-overlay"
      @enter="onOverlayEnter"
      @leave="onOverlayLeave"
    >
      <div
        v-if="overlayVisible"
        :ref="overlayRef"
        class="multiselect-panel component"
      >
        <div class="multiselect-header">
          <div
            class="checkbox component"
            role="checkbox"
            :aria-checked="allSelected"
            @click="onToggleAll"
          >
            <div class="hidden-accessible">
              <input
                type="checkbox"
                readonly
                @focus="onHeaderCheckboxFocus"
                @blur="onHeaderCheckboxBlur"
              >
            </div>
            <div
              :class="['checkbox-box', {'highlight': allSelected, 'focus': headerCheckboxFocused}]"
              role="checkbox"
              :aria-checked="allSelected"
            >
              <a-icon 
                v-if="allSelected"
                class="checkbox-icon absolute"
                size="lg"
                name="check" 
              />
              <span
                v-else
                class="checkbox-icon"
              />
            </div>
          </div>
          <div
            v-if="filter"
            class="multiselect-filter-container"
          >
            <input
              v-model="filterValue"
              type="text"
              class="multiselect-filter inputtext component"
              :placeholder="filterPlaceholder"
              @input="onFilterChange"
            >
            <a-icon
              class="multiselect-filter-icon"
              name="search"
            />
          </div>
          <button
            class="multiselect-close link"
            type="button"
            @click="onCloseClick"
          >
            <a-icon
              class="multiselect-close-icon"
              name="times"
              size="lg"
            />
          </button>
        </div>
        <div
          class="multiselect-items-wrapper"
          :style="{'max-height': scrollHeight}"
        >
          <ul
            class="multiselect-items component"
            role="listbox"
            aria-multiselectable="true"
          >
            <li
              v-for="(option, i) of visibleOptions"
              :key="getOptionRenderKey(option)"
              :class="['multiselect-item', {'highlight': isSelected(option), 'disabled': isOptionDisabled(option)}]"
              role="option"
              :aria-selected="isSelected(option)"
              :aria-label="getOptionLabel(option)"
              :tabindex="tabindex||'0'"
              @click="onOptionSelect($event, option)"
              @keydown="onOptionKeyDown($event, option)"
            >
              <div class="checkbox component">
                <div :class="['checkbox-box', {'highlight': isSelected(option)}]">
                  <a-icon
                    v-if="isSelected(option)"
                    class="checkbox-icon absolute"
                    size="lg"
                    name="check"
                  />
                  <span
                    v-else
                    class="checkbox-icon"
                  />
                </div>
              </div>
              <slot
                name="option"
                :option="option"
                :index="i"
              >
                <span>{{ getOptionLabel(option) }}</span>
              </slot>
            </li>
            <li
              v-if="filterValue && (!visibleOptions || (visibleOptions && visibleOptions.length === 0))"
              class="multiselect-empty-message"
            >
              {{ emptyFilterMessage }}
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ConnectedOverlayScrollHandler from '@/utils/scroll.js';
import ObjectUtils from '@/utils/ObjectUtils.js';
import DomHandler from '@/utils/dom.js';

export default {
  name: 'AMultiSelect',
  props: {
    modelValue: {
      type: [String, Number, Object, Array],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    optionLabel: {
      type: String,
      default: null
    },
    optionValue: {
      type: String,
      default: null
    },
    optionDisabled: {
      type: Boolean,
      default: null
    },
    scrollHeight: {
      type: String,
      default: '200px'
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Boolean,
      default: false
    },
    inputId: {
      type: String,
      default: null
    },
    tabindex: {
      type: [String, Number],
      default: null
    },
    dataKey: {
      type: String,
      default: null
    },
    filterPlaceholder: {
      type: String,
      default: null
    },
    filterLocale: {
      type: String,
      default: undefined
    },
    ariaLabelledBy: {
      type: String,
      default: null
    },
    appendTo: {
        type: String,
        default: null
    },
    emptyFilterMessage: {
        type: String,
        default: 'No results found'
    },
    display: {
        type: String,
        default: 'comma'
    }
  },
  emits: [
    'update:modelValue',
    'before-show',
    'before-hide',
    'change',
    'show',
    'hide',
    'filter'
  ],
  data() {
      return {
          focused: false,
          headerCheckboxFocused: false,
          filterValue: null,
          overlayVisible: false
      };
  },
  outsideClickListener: null,
  resizeListener: null,
  scrollHandler: null,
  overlay: null,
  computed: {
      visibleOptions() {
          if (this.filterValue && this.filterValue.trim().length > 0)
              return this.options.filter(option => this.getOptionLabel(option).toLocaleLowerCase(this.filterLocale).indexOf(this.filterValue.toLocaleLowerCase(this.filterLocale)) > -1);
          else
              return this.options;
      },
      containerClass() {
          return [
              'multiselect component inputwrapper',
              {
                  'multiselect-chip': this.display === 'chip',
                  'disabled': this.disabled,
                  'focus': this.focused,
                  'inputwrapper-filled': this.modelValue && this.modelValue.length,
                  'inputwrapper-focus': this.focused
              }
          ];
      },
      labelClass() {
          return [
              'multiselect-label',
              {
                  'placeholder': this.label === this.placeholder,
                  'multiselect-label-empty': !this.placeholder && (!this.modelValue || this.modelValue.length === 0)
              }
          ];
      },
      label() {
          let label;

          if (this.modelValue && this.modelValue.length) {
              label = '';
              for(let i = 0; i < this.modelValue.length; i++) {
                  if(i !== 0) {
                      label += ', ';
                  }

                  label += this.getLabelByValue(this.modelValue[i]);
              }
          }
          else {
              label = this.placeholder;
          }

          return label;
      },
      allSelected() {
          if (this.filterValue && this.filterValue.trim().length > 0) {
              let allSelected = true;
      if(this.visibleOptions.length > 0) {
        for (let option of this.visibleOptions) {
          if (!this.isSelected(option)) {
            allSelected = false;
            break;
          }
        }
              }
              else
                  allSelected = false;
              return allSelected;
          }
          else {
              return this.modelValue && this.options && (this.modelValue.length > 0 && this.modelValue.length === this.options.length);
          }
      },
      equalityKey() {
          return this.optionValue ? null : this.dataKey;
      }
  },
  beforeUnmount() {
      this.restoreAppend();
      this.unbindOutsideClickListener();
      this.unbindResizeListener();

      if (this.scrollHandler) {
          this.scrollHandler.destroy();
          this.scrollHandler = null;
      }
      this.overlay = null;
  },
  methods: {
      getOptionLabel(option) {
          return this.optionLabel ? ObjectUtils.resolveFieldData(option, this.optionLabel) : option;
      },
      getOptionValue(option) {
          return this.optionValue ? ObjectUtils.resolveFieldData(option, this.optionValue) : option;
      },
      getOptionRenderKey(option) {
          return this.dataKey ? ObjectUtils.resolveFieldData(option, this.dataKey) : this.getOptionLabel(option);
      },
      isOptionDisabled(option) {
          return this.optionDisabled ? ObjectUtils.resolveFieldData(option, this.optionDisabled) : false;
      },
      isSelected(option) {
          let selected = false;
          let optionValue = this.getOptionValue(option);

          if (this.modelValue) {
              for (let val of this.modelValue) {
                  if (ObjectUtils.equals(val, optionValue, this.equalityKey)) {
                      selected = true;
                      break;
                  }
              }
          }

          return selected;
      },
      show() {
          this.$emit('before-show');
          this.overlayVisible = true;
      },
      hide() {
          this.$emit('before-hide');
          this.overlayVisible = false;
      },
      onFocus() {
          this.focused = true;
      },
      onBlur() {
          this.focused = false;
      },
      onHeaderCheckboxFocus() {
          this.headerCheckboxFocused = true;
      },
      onHeaderCheckboxBlur() {
          this.headerCheckboxFocused = false;
      },
      onClick(event) {
          if (!this.disabled && (!this.overlay || !this.overlay.contains(event.target)) && !DomHandler.hasClass(event.target, 'multiselect-close')) {
              DomHandler.hasClass(event.target, 'multiselect-close');
              if (this.overlayVisible)
                  this.hide();
              else
                  this.show();

              this.$refs.focusInput.focus();
          }
      },
      onCloseClick() {
          this.hide();
      },
      onKeyDown(event) {
          switch(event.which) {
              //down
              case 40:
                  if (this.visibleOptions && !this.overlayVisible && event.altKey) {
                      this.show();
                  }
              break;

              //space
              case 32:
                  if (!this.overlayVisible) {
                      this.show();
                      event.preventDefault();
                  }
              break;

              //enter and escape
              case 13:
              case 27:
                  if (this.overlayVisible) {
                      this.hide();
                      event.preventDefault();
                  }
              break;

              //tab
              case 9:
                  this.hide();
              break;

              default:
              break;
          }
      },
      onOptionSelect(event, option) {
          if (this.disabled || this.isOptionDisabled(option)) {
              return;
          }

          let selected = this.isSelected(option);
          let value = null;

          if (selected)
              value = this.modelValue.filter(val => !ObjectUtils.equals(val, this.getOptionValue(option), this.equalityKey));
          else
              value = [...this.modelValue || [], this.getOptionValue(option)];

          this.$emit('update:modelValue', value);
          this.$emit('change', {originalEvent: event, value: value});
      },
      onOptionKeyDown(event, option) {
          let listItem = event.target;

          switch(event.which) {
              //down
              case 40:
                  var nextItem = this.findNextItem(listItem);
                  if (nextItem) {
                      nextItem.focus();
                  }

                  event.preventDefault();
              break;

              //up
              case 38:
                  var prevItem = this.findPrevItem(listItem);
                  if (prevItem) {
                      prevItem.focus();
                  }

                  event.preventDefault();
              break;

              //enter
              case 13:
                  this.onOptionSelect(event, option);
                  event.preventDefault();
              break;

              default:
              break;
          }
      },
      findNextItem(item) {
          let nextItem = item.nextElementSibling;

          if (nextItem)
              return DomHandler.hasClass(nextItem, 'disabled') ? this.findNextItem(nextItem) : nextItem;
          else
              return null;
      },
      findPrevItem(item) {
          let prevItem = item.previousElementSibling;

          if (prevItem)
              return DomHandler.hasClass(prevItem, 'disabled') ? this.findPrevItem(prevItem) : prevItem;
          else
              return null;
      },
      onOverlayEnter() {
          this.overlay.style.zIndex = String(DomHandler.generateZIndex());
          this.appendContainer();
          this.alignOverlay();
          this.bindOutsideClickListener();
          this.bindScrollListener();
          this.bindResizeListener();
          this.$emit('show');
      },
      onOverlayLeave() {
          this.unbindOutsideClickListener();
          this.unbindScrollListener();
          this.unbindResizeListener();
          this.$emit('hide');
          this.overlay = null;
      },
      alignOverlay() {
          if (this.appendTo) {
              DomHandler.absolutePosition(this.overlay, this.$el);
              this.overlay.style.minWidth = DomHandler.getOuterWidth(this.$el) + 'px';
          }
          else {
              DomHandler.relativePosition(this.overlay, this.$el);
          }
      },
      bindOutsideClickListener() {
          if (!this.outsideClickListener) {
              this.outsideClickListener = (event) => {
                  if (this.overlayVisible && this.isOutsideClicked(event)) {
                      this.hide();
                  }
              };
              document.addEventListener('click', this.outsideClickListener);
          }
      },
      unbindOutsideClickListener() {
          if (this.outsideClickListener) {
              document.removeEventListener('click', this.outsideClickListener);
              this.outsideClickListener = null;
          }
      },
      bindScrollListener() {
          if (!this.scrollHandler) {
              this.scrollHandler = new ConnectedOverlayScrollHandler(this.$refs.container, () => {
                  if (this.overlayVisible) {
                      this.hide();
                  }
              });
          }

          this.scrollHandler.bindScrollListener();
      },
      unbindScrollListener() {
          if (this.scrollHandler) {
              this.scrollHandler.unbindScrollListener();
          }
      },
      bindResizeListener() {
          if (!this.resizeListener) {
              this.resizeListener = () => {
                  if (this.overlayVisible) {
                      this.hide();
                  }
              };
              window.addEventListener('resize', this.resizeListener);
          }
      },
      unbindResizeListener() {
          if (this.resizeListener) {
              window.removeEventListener('resize', this.resizeListener);
              this.resizeListener = null;
          }
      },
      isOutsideClicked(event) {
          return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target) || (this.overlay && this.overlay.contains(event.target)));
      },
      getLabelByValue(val) {
          let label = null;

          if (this.options) {
              for (let option of this.options) {
                  let optionValue = this.getOptionValue(option);

                  if(ObjectUtils.equals(optionValue, val, this.equalityKey)) {
                      label = this.getOptionLabel(option);
                      break;
                  }
              }
          }

          return label;
      },
      onToggleAll(event) {
          const value = this.allSelected ? [] : this.visibleOptions  && this.visibleOptions.map(option => this.getOptionValue(option));

          this.$emit('update:modelValue', value);
          this.$emit('change', {originalEvent: event, value: value});
      },
      appendContainer() {
          if (this.appendTo) {
              if (this.appendTo === 'body')
                  document.body.appendChild(this.overlay);
              else
                  document.getElementById(this.appendTo).appendChild(this.overlay);
          }
      },
      restoreAppend() {
          if (this.overlay && this.appendTo) {
              if (this.appendTo === 'body')
                  document.body.removeChild(this.overlay);
              else
                  document.getElementById(this.appendTo).removeChild(this.overlay);
          }
      },
      onFilterChange(event) {
          this.$emit('filter', {originalEvent: event, value: event.target.value});
          if (this.overlayVisible) {
              this.alignOverlay();
          }
      },
      overlayRef(el) {
          this.overlay = el;
      },
      removeChip(item) {
          let value = this.modelValue.filter(val => !ObjectUtils.equals(val, item, this.equalityKey));

          this.$emit('update:modelValue', value);
          this.$emit('change', {originalEvent: event, value: value});
      }
  }
}
</script>
