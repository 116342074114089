import http from '@/services/http';
import { getCookie, setCookie, removeCookie } from 'tiny-cookie';

const state = {
  impersonate: !!getCookie('impersonate_token'),
  uuid: !!getCookie('impersonate_uuid'),
  token: !!getCookie('impersonate_token'),
};

const getters = {
  isImpersonating: (state, getters, rootState, rootGetters) => state.impersonate && rootGetters['auth/isUser']
};

const actions = {
  async impersonate({ commit, dispatch }, {conference, uuid}) {
    try {
      let { data: { data } } = await http.post(`conferences/${conference}/user/impersonate/${uuid}`);

      commit('SET_IMPERSONATE', data)
      dispatch('auth/setUserAuth', data.user, { root: true })
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async stopImpersonating({ commit, dispatch }) {
    await dispatch('auth/clearAuth', null, { root: true })
    commit('STOP_IMPERSONATE')
  },
  clearImpersonating({ commit }) {
    commit('STOP_IMPERSONATE')
  }
};

const mutations = {
  SET_IMPERSONATE(state, { uuid, token }) {
    state.impersonate = true;
    state.token = token;
    state.uuid = uuid;

    setCookie('impersonate_token', token);
    setCookie('impersonate_uuid', uuid);
  },
  STOP_IMPERSONATE(state) {
    state.impersonate = false;
    state.token = null;
    state.uuid = null;

    removeCookie('impersonate_uuid');
    removeCookie('impersonate_token');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
