import http from '@/services/http';

const state = {
  attendees: [],
  loaded: false
};

const getters = {
  conference: (state, getters, rootState, rootGetters) =>
    rootGetters['conferences/current'],
};

const actions = {
  async all({ commit, getters }) {
    let {
      data: { data }
    } = await http.get(`conferences/${getters.conference}/attendees`);

    commit('SET_ATTENDEES', data);

    return data;
  },
  async email({ getters }, payload) {
    let response = await http.post(`conferences/${getters.conference}/attendees/message`, payload);

    return response;
  },
};

const mutations = {
  SET_ATTENDEES(state, rows) {
    state.attendees = rows
    state.loaded = true;
  },
  CLEAR_ATTENDEES(state) {
    state.attendees = []
    state.loaded = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
