<template>
  <li
    class="group px-6 py-4 border-b border-gray-200 hover:border-l-4 hover:border-l-primary"
    :class="{'border-l-4 border-l-primary': active}"
  >
    <a
      class="flex justify-between cursor-pointer"
      @click.prevent="onClick"
    >
      <div class="flex flex-row space-x-5 flex-grow">
        <div
          v-if="icon"
          class="mt-1.5 w-8"
        >
          <a-icon
            type="fas"
            :name="icon"
            size="lg"
            :class="{'text-gray-900': !active, 'text-primary': active}"
            class="group-hover:text-primary"
          />
        </div>
        <div
          class="group-hover:text-primary text-xl mt-1"
          :class="{'text-gray-900': !active, 'text-primary': active}"
          v-text="label"
        />
      </div>
      <div v-if="badge !== undefined">
        <div
          class="px-2 py-1 rounded-md bg-gray-300 bg-opacity-50 text-lg text-gray-900"
          v-text="badge"
        /> 
      </div>
    </a>
  </li>
</template>

<script>
import { get } from '@/utils/lodash';
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    icon() {
      return get(this.item, 'icon');
    },
    label() {
      return get(this.item, 'label');
    },
    badge() {
      return get(this.item, 'badge');
    }
  },
  methods: {
    onClick() {
      this.$emit('click', this.item);
    }
  }
};
</script>