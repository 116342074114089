<template>
  <div class="ml-3 relative">
    <div v-if="isUser">
      <button
        id="user-menu"
        class="max-w-xs flex items-center text-base rounded-full focus:outline-none focus:bg-cool-gray-100 lg:p-2 lg:rounded-md lg:hover:bg-cool-gray-200"
        aria-label="User menu"
        aria-haspopup="true"
        @click="open = !open"
      >
        <profile-picture :user="user" />
        <span class="mx-2">{{ name }}</span>
      </button>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="open"
        v-click-away="onClickAway"
        class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
      >
        <div
          class="py-1 rounded-md bg-white shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <template v-if="conferences.length > 1">
            <conference-menu-item
              v-for="conference in conferences"
              :key="conference.id"
              :conference="conference"
              @click="onMenuClicked"
            />
            <div class="border-t border-gray-200" />
          </template>
          <router-link
            :to="{ name: 'home' }"
            class="cursor-pointer block px-4 py-2 text-base text-cool-gray-700 hover:bg-cool-gray-100 transition ease-in-out duration-150"
          >
            Conferences
          </router-link>
          <div class="border-t border-gray-200" />
          <router-link
            :to="{ name: 'account.details' }"
            class="cursor-pointer block px-4 py-2 text-base text-cool-gray-700 hover:bg-cool-gray-100 transition ease-in-out duration-150"
          >
            Personal Details
          </router-link>
          <a
            class="cursor-pointer block px-4 py-2 text-base text-cool-gray-700 hover:bg-cool-gray-100 transition ease-in-out duration-150"
            role="menuitem"
            @click.prevent="onLogout"
          >Logout</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mixin as ClickAway } from 'vue3-click-away';
import { mapActions, mapGetters } from 'vuex';
import ConferenceMenuItem from '@/components/conferences/navigation/menu.vue';
import ProfilePicture from '@/components/users/profile-image.vue';
export default {
  components: {
    ProfilePicture,
    ConferenceMenuItem
  },
  mixins: [ClickAway],
  data: () => ({
    open: false
  }),
  computed: {
    ...mapGetters('auth', ['isUser', 'name', 'user']),
    ...mapGetters('conferences', ['current']),
    ...mapGetters('user', ['conferences'])
  },
  watch: {
    $route() {
      this.open = false;
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    onClickAway() {
      this.open = false;
    },
    onMenuClicked() {
      this.open = false;
    },
    async onLogout() {
      this.open = false;

      try {
        await this.logout();
      } catch (e) {
        console.error(e);
      }

      this.$router.replace({
        name: 'auth',
        params: { conference: this.current }
      });
    }
  }
};
</script>