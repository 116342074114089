<script>
import { filter, find } from '@/utils/lodash';
export default {
  name: 'ATabs',
  props: {
    active: {
      type: String,
      required: true
    },
    pills: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: true
    }
  },
  emits: ['change'],
  data: () => ({
    tabs: [],
    mobileSelectedTab: null
  }),
  computed: {
    activeTab() {
      return find(this.tabs, t => t.name === this.active);
    }
  },
  watch: {
    active: {
      immediate: true,
      handler: 'onActiveSet'
    },
    mobileSelectedTab() {
      const tab = this.getTab(this.mobileSelectedTab);
      if (tab) {
        this.onTabChange(tab);
      }
    }
  },
  methods: {
    getTab(name) {
      return find(this.tabs, t => t.name === name);
    },
    onActiveSet() {
      if (this.mobileSelectedTab != this.active) {
        this.mobileSelectedTab = this.active;
      }
    },
    onTabChange(tab) {
      if (tab.disabled) {
        return;
      }
      this.$emit('change', tab);
    },
    addTab(tab) {
      this.tabs.push(tab);
    },
    removeTab(tab) {
      this.tabs = filter(this.tabs, t => t !== tab);
    }
  }
};
</script>

<template>
  <div :class="['tabs', pills ? 'tabs--pills' : null]">
    <div class="tabs__mobile">
      <select
        v-model="mobileSelectedTab"
        class="w-full p-2 outline-primary my-6"
      >
        <option
          v-for="tab in tabs"
          :key="tab.name"
          :value="tab.name"
          :disabled="tab.disabled"
        >
          {{ tab.title }}
        </option>
      </select>
    </div>
    <div class="tabs__desktop">
      <nav class="tabs__desktop__nav">
        <a
          v-for="tab in tabs"
          :key="tab.name"
          :class="[
            'tabs__desktop__nav__item',
            active === tab.name ? 'tabs__desktop__nav__item--active' : null,
            tab.disabled ? 'tabs__desktop__nav__item--disabled' : null,
            full ? 'tabs__desktop__nav__item--full' : null
          ]"
          :aria-current="active ? 'page' : null"
          @click="onTabChange(tab)"
        >
          {{ tab.title }}
        </a>
      </nav>
    </div>
    <div class="tabs__content">
      <slot />
    </div>
  </div>
</template>