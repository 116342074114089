<template>
  <div class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
    <div class="max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex p-2 rounded-lg bg-indigo-800">
              <slot name="icon">
                <a-icon
                  name="bullhorn"
                  class="text-white"
                />
              </slot>
            </span>
            <p class="ml-3 font-medium text-white truncate">
              <span class="md:hidden">
                <slot name="mobile" />
              </span>
              <span class="hidden md:inline">
                <slot />
              </span>
            </p>
          </div>
          <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <div class="rounded-md shadow-sm">
              <slot name="action" />
            </div>
          </div>
          <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
            <button
              type="button"
              class="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500 transition ease-in-out duration-150"
              aria-label="Dismiss"
              @click="onClose"
            >
              <a-icon
                v-if="dismissible"
                name="times"
                size="lg"
                class="text-white"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dismissible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  methods: {
    onClose() {
      this.$emit('close');
    }
  }
};
</script>