import http from '@/services/http';
import dayjs, { format } from '@/utils/dates';
import { isEmpty, groupBy, find, head, map, each } from '@/utils/lodash';

const state = {
  seminars: [],
  loaded: false
};

const getters = {
  hasSeminars: state => !isEmpty(state.seminars),
  find: state => id => find(state.seminars, row => row.id == id),
  conference: (state, getters, rootState, rootGetters) =>
    rootGetters['conferences/current'],
  daySeminars: (state, getters) => day => {
    let date = day || dayjs().format('YYYY-MM-DD');

    let seminars = find(getters.grouped, (i, day) => day == date);

    if (isEmpty(seminars)) {
      return {};
    }

    return { [date]: seminars };
  },
  hasSeminarsToday: (state, getters) => !isEmpty(getters.todaysSeminars),
  grouped: state => {
    let dates = groupBy(state.seminars, seminar => format(seminar.slot.start, 'YYYY-MM-DD'));
    let days = {};
    each(dates, dates => {
      days[format(head(map(dates, 'slot.start')), 'YYYY-MM-DD')] = dates;
    })
    return days;
  },
  dates: (state, getters) => {
    return Object.keys(getters.grouped)
  }
};

const actions = {
  async all({ commit, getters }) {
    try {
      const { data: { data } } = await http.get(`conferences/${getters.conference}/seminars`)

      commit('SET_SEMINARS', data)

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async get({ getters }, id) {
    try {
      const { data: { data } } = await http.get(`conferences/${getters.conference}/seminars/${id}`)

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
};

const mutations = {
  SET_SEMINARS(state, rows) {
    state.seminars = rows;    
    state.loaded = true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
