import http from '@/services/http';

const state = {};

const getters = {
  conference: (state, getters, rootState, rootGetters) =>
    rootGetters['conferences/current']
};

const actions = {
  async create({ dispatch, getters }, {exhibitor, form}) {
    try {
      const { data: { data } } = await http.post(`conferences/${getters.conference}/exhibitors/${exhibitor}/videos`, form)

      await dispatch('exhibitors/exhibitor/get', exhibitor, {root: true});

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async update({ dispatch, getters }, {exhibitor, id, form}) {
    try {
      const { data: { data } } = await http.put(`conferences/${getters.conference}/exhibitors/${exhibitor}/videos/${id}`, form)

      await dispatch('exhibitors/exhibitor/get', exhibitor, {root: true});

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async delete({ getters, dispatch }, {exhibitor, id}) {
    try {
      const { data: { data } } = await http.delete(`conferences/${getters.conference}/exhibitors/${exhibitor}/videos/${id}`)

      dispatch('exhibitors/exhibitor/get', exhibitor, {root: true});

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
