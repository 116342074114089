<template>
  <a-card>
    <ul class="flex flex-col">
      <nav-item
        v-for="item in items"
        :key="item.key"
        :item="item"
        :active="active === item.key"
        @click="onClick"
      />
    </ul>
  </a-card>
</template>

<script>
import NavItem from './item.vue';
export default {
  name: 'ANavigationGroup',
  components: {
    NavItem
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    active: {
      type: String,
      required: true
    }
  },
  emits: ['click'],
  methods: {
    onClick(item) {
      this.$emit('click', item);
    }
  }
};
</script>