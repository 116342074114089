<template>
  <div class="hidden md:flex md:flex-shrink-0">
    <div class="flex flex-col w-56">
      <div
        class="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto"
      >
        <div class="flex justify-center flex-shrink-0 px-4">
          <logo class="max-h-24 w-auto" />
        </div>
        <div class="mt-5 flex-grow flex flex-col">
          <navigation class="flex-1 px-2 bg-white space-y-1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/layout/header/logo.vue';
import Navigation from '@/components/layout/navigation.vue';
export default {
  components: {
    Logo,
    Navigation
  }
};
</script>