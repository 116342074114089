export default {
  props: {
    round: {
      type: String,
      default: 'md',
      validator: function (value) {
        return ['none', 'sm', 'md', 'lg', 'full'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    roundClass () {
      return `rounded-${this.round}`
    }
  }
}
