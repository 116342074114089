<template>
  <span :class="spanClasses">
    <img
      v-if="isImage"
      :class="imageClasses"
      :src="source"
    >
    <span
      v-else
      :class="placeholderClasses"
    >
      <slot name="placeholder" />
    </span>
    <slot name="badge" />
  </span>
</template>

<script>
import size from '@/mixins/size.js';
import round from '@/mixins/round.js';
import { startsWith } from '@/utils/lodash';
export default {
  name: 'AAvatar',
  mixins: [round, size],
  props: {
    source: {
      type: String,
      default: null
    },
    stacked: {
      type: [Boolean, String],
      default: false
    },
    placeholderClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    isImage() {
      return (
        startsWith(this.source, 'http://') ||
        startsWith(this.source, 'https://')
      );
    },
    hasBadge() {
      return !!this.$slots.badge;
    },
    imageClasses() {
      return [...this.variantClasses];
    },
    variantClasses() {
      return [this.roundClass, this.sizeClass];
    },
    spanClasses() {
      return [
        'avatar',
        this.roundClass,
        ...this.stackedClasses,
        this.hasBadge ? 'relative' : null
      ];
    },
    placeholderClasses() {
      return [
        'avatar__placeholder',
        ...this.variantClasses,
        this.placeholderClass
      ];
    },
    stackedClasses() {
      if (!this.stacked) {
        return [];
      }

      if (typeof this.stacked === 'string') {
        return [`-ml-${this.stacked}`];
      }

      return ['-ml-2'];
    }
  }
};
</script>