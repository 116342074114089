<template>
  <router-view />
  <a-notification
    :position="['top', 'center']"
    expand
  />
</template>

<script>
import { watch } from 'vue';
import { isEmpty } from 'utils/lodash';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { setUser } from '@/services/zendesk';
import debug from '@/utils/debug';
export default {
  name: 'App',
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('conferences', ['conferences'])
  },
  watch: {
    user: {
      handler: 'onUserChange'
    }
  },
  created() {
    this.fetchOptions();
    this.loadConferences();
    watch(debug, value => {
      this.setDebug(value);
    });
  },
  methods: {
    ...mapActions('options', { fetchOptions: 'all' }),
    ...mapActions('conferences', ['all']),
    ...mapMutations('debug', { setDebug: 'SET_DEBUG' }),
    loadConferences() {
      if (isEmpty(this.conferences)) {
        this.all();
      }
    },
    onUserChange() {
      setUser(this.user);
    }
  }
};
</script>
