<template>
  <input
    v-model="value"
    v-bind="$attrs"
    :class="{error}"
  >
</template>

<script>
import { computed } from 'vue';
export default {
  name: 'AInput',
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });

    return {
      value
    };
  }
};
</script>
