import http from '@/services/http';
import { find, get, isEmpty } from '@/utils/lodash';

const state = {
  conferences: null,
  current: null,
  conference: null
};

const getters = {
  conferences: state => state.conferences,
  loaded: state => state.conferences !== null,
  has: state => !isEmpty(state.conferences),
  current: state => state.current,
  conference: (state, getters) => {
    // Do we have conference set
    let conference = state.conference;

    if(!isEmpty(conference)) {
      return conference;
    }

    // See if it exists in the conferences
    return getters.find(state.current)
  },
  find: state => slug =>
    find(state.conferences, conference => conference.slug === slug),
  isValidConference: (state, getters) => !isEmpty(getters.conference),
  features: (state, getters) => get(getters.conference, 'features'),
  hasSchedule: (state, getters) => get(getters.features, 'schedule', false),
  hasAttendeesList: (state, getters, rootState, rootGetters) => get(getters.features, 'attendeesList', false) && rootGetters['auth/isExhibitor'],
  hasBooths: (state, getters) => get(getters.features, 'vitualBooths', false),
  hasSeminars: (state, getters) => get(getters.features, 'seminars', false),
  hasStreams: (state, getters) => get(getters.features, 'liveStream', false),
  currentConferenceImage: (state, getters) => {
    return get(getters.conference, 'logo.path');
  },
  sponsor: (state, getters) => get(getters.conference, 'sponsor'),
  hasSponsor: (state, getters) => !isEmpty(getters.sponsor)
};

const actions = {
  async all({ commit }) {
    let response = await http.get('conferences');

    commit('CONFERENCES_SET_ALL', response.data.data);
  },
  // eslint-disable-next-line no-empty-pattern
  async get({ commit }, slug) {
    let {
      data: { data }
    } = await http.get(`conferences/${slug}`);

    commit('SET_CURRENT_CONFERENCE', data);

    return data;
  },
  setCurrent({ commit }, slug) {
    commit('SET_CURRENT_CONFERENCE_SLUG', slug);
  },
  clearCurrent({ commit }) {
    commit('CLEAR_CURRENT_CONFERENCE');
  }
};

const mutations = {
  CONFERENCES_SET_ALL(state, rows) {
    state.conferences = rows;
  },
  SET_CURRENT_CONFERENCE_SLUG(state, slug) {
    state.current = slug;
  },
  SET_CURRENT_CONFERENCE(state, conference) {
    state.conference = conference;
  },
  CLEAR_CURRENT_CONFERENCE(state) {
    state.current = null;
    state.conference = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
