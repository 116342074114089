<template>
  <nav
    v-if="current"
    class="sidebar-nav"
  >
    <router-link :to="{ name: 'dashboard', params: {conference: current} }">
      <a-icon
        name="home"
        size="lg"
        class="icon"
      />Dashboard
    </router-link>
    <router-link :to="{ name: 'my.schedule', params: {conference: current} }">
      <a-icon
        name="clock"
        size="lg"
        class="icon"
      />My Schedule
    </router-link>
    <router-link
      v-if="hasSchedule"
      :to="{ name: 'schedule.index', params: {conference: current} }"
    >
      <a-icon
        name="calendar-check"
        size="lg"
        class="icon"
      />Show Schedule
    </router-link>
    <virtual-booth-navigation v-if="isUser && hasBooths" />
    <router-link
      v-if="hasSeminars"
      :to="{ name: 'seminars.index', params: {conference: current} }"
    >
      <a-icon
        name="keynote"
        size="lg"
        class="icon"
      />Seminar Program
    </router-link>
    <router-link
      v-if="hasStreams"
      :to="{ name: 'streaming.index', params: {conference: current} }"
    >
      <a-icon
        name="signal-stream"
        size="lg"
        class="icon icon--active"
      />Now Streaming
    </router-link>
    <router-link
      v-if="hasAttendeesList"
      :to="{ name: 'attendees.index', params: {conference: current} }"
    >
      <a-icon
        name="clipboard-user"
        size="lg"
        class="icon pl-1"
      />Attendees
    </router-link>
    <management-navigation v-if="isManager" />
  </nav>
  <div v-else>
    <navigation-loader rows="3" />
  </div>
</template>

<script>
import NavigationLoader from '@/components/loaders/navigation.vue';
import ManagementNavigation from '@/components/management/navigation.vue';
import VirtualBoothNavigation from './navigation/booth-navigation.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    NavigationLoader,
    ManagementNavigation,
    VirtualBoothNavigation
  },
  computed: {
    ...mapGetters('auth', ['isUser']),
    ...mapGetters('management', ['isManager']),
    ...mapGetters('conferences', [
      'current',
      'hasSeminars',
      'hasBooths',
      'hasSchedule',
      'hasAttendeesList',
      'hasStreams'
    ])
  }
};
</script>

<style>
nav.sidebar-nav {
  a {
    @apply group flex items-center px-2 py-2 text-base leading-5 text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150;

    &.router-link-active {
      @apply text-gray-800 bg-gray-200 hover:text-gray-900 hover:bg-gray-200 focus:bg-gray-200;
    }
  }

  .icon {
    @apply flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150;

    &--active {
      @apply text-red-600 animate-pulse;
    }
  }
}
</style>