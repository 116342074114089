import http from '@/services/http';
import dayjs, { format } from '@/utils/dates';
import { isEmpty, groupBy, find, size, get, head, map, each } from '@/utils/lodash';

const state = {
  conferences: [],
  schedule: null
};

const getters = {
  conferences: state => state.conferences,
  has: state => !isEmpty(state.conferences),
  conference: (state, getters, rootState, rootGetters) =>
    rootGetters['conferences/current'],
  scheduleLoaded: state => state.schedule !== null,
  total: state => size(state.schedule),
  subscribed: state => id => find(state.schedule, meeting => meeting.id === id),
  todaysSchedule: (state, getters) => get(getters.groupedMeetings, dayjs().format('YYYY-MM-DD'), []),
  hasMeetingsToday: (state, getters) => !isEmpty(getters.todaysSchedule),
  groupedMeetings: state => {
    let dates = groupBy(state.schedule, schedule => format(schedule.slot.start, 'YYYY-MM-DD'));
    let days = {};
    each(dates, dates => {
      days[format(head(map(dates, 'slot.start')), 'YYYY-MM-DD')] = dates;
    })
    return days;
  },
  countByType: (state, getters) => type => {
    return size(get(getters.groupedType, type))
  },
  groupedType: state => {
    return groupBy(state.schedule, schedule => schedule.type.key);
  },
  scheduleDates: (state, getters) => {
    return Object.keys(getters.groupedMeetings)
  }
};

const actions = {
  async conferences({ commit, getters }) {
    let {
      data: { data }
    } = await http.get(`conferences/${getters.conference}/user/conferences`);

    commit('SET_USER_CONFERENCES', data);

    return data;
  },
  async fetchSchedule({ commit, getters }) {
    let {
      data: { data }
    } = await http.get(`conferences/${getters.conference}/user/schedule`);

    commit('SET_USER_SCHEDULE', data);

    return data;
  },
  clearSchedule({ commit }) {
    commit('CLEAR_USER_SCHEDULE');
  }
};

const mutations = {
  SET_USER_CONFERENCES(state, rows) {
    state.conferences = rows;
  },
  SET_USER_SCHEDULE(state, rows) {
    state.schedule = rows;
  },
  CLEAR_USER_SCHEDULE(state) {
    state.schedule = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
