<template>
  <conference-logo
    v-if="hasImage"
    :conference="conference"
  />
  <img
    v-else
    src="@/assets/images/avn_logo.png"
    alt
  >
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from '@/utils/lodash';
import ConferenceLogo from '@/components/conferences/cards/logo.vue';
export default {
  components: {
    ConferenceLogo
  },
  computed: {
    ...mapGetters('conferences', ['conference', 'currentConferenceImage']),
    hasImage() {
      return !isEmpty(this.currentConferenceImage);
    }
  }
};
</script>