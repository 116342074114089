import http from '@/services/http';
import { filter, isEmpty } from "@/utils/lodash"

const state = {
  exhibitors: []
};

const getters = {
  exhibitors: state => filter(state.exhibitors, row => !row.is_sponsor),
  sponsors: state => filter(state.exhibitors, row => row.is_sponsor && !isEmpty(row.card_logo)),
  conference: (state, getters, rootState, rootGetters) =>
    rootGetters['conferences/current']
};

const actions = {
  async all({ commit }, conference) {
    try {
      const { data: { data } } = await http.get(`conferences/${conference}/exhibitors`)

      commit('SET_EXHIBITORS', data)

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async get({ getters }, id) {
    try {
      const { data: { data } } = await http.get(`conferences/${getters.conference}/exhibitors/${id}`)

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
};

const mutations = {
  SET_EXHIBITORS(state, rows) {
      state.exhibitors = rows;    
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
