<template>
  <div
    v-if="open"
    class="modal fixed z-50 inset-0 overflow-y-auto"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-900 opacity-50" />
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
      &#8203;
      <div
        class="modal__dialog"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        :class="{'modal__dialog--wide': wide}"
      >
        <div
          v-if="dismissible"
          class="absolute top-0 right-0 -mt-12 sm:-mt-5 sm:-mr-8"
        >
          <a-button
            variant
            size="xs"
            class="modal__close"
            @click="onClose"
          >
            <a-icon
              variant
              class="modal__close__icon text-lg"
              name="times"
              size="lg"
            />
          </a-button>
        </div>
        <div
          v-if="hasHeader"
          class="modal__dialog__header"
        >
          <slot name="header">
            {{ title }}
          </slot>
        </div>
        <div class="modal__dialog__content">
          <slot />
        </div>
        <div
          v-if="hasFooter"
          class="modal__dialog__footer"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AModal',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    dismissible: {
      type: Boolean,
      default: true
    },
    wide: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  computed: {
    hasIcon() {
      return !!this.$slots['icon'];
    },
    hasHeader() {
      return !!this.$slots['header'] || this.title;
    },
    hasFooter() {
      return !!this.$slots['footer'];
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    }
  }
};
</script>