<template>
  <div :class="['hero-banner', `hero-banner--${variant}`]">
    <div class="flex flex-col">
      <h1
        v-if="$slots.header"
        :class="['hero-banner__header', `hero-banner__header--${variant}`]"
      >
        <slot name="header" />
      </h1>
      <div :class="['hero-banner__content', `hero-banner__content--${variant}`]">
        <slot />
      </div>
    </div>
    <slot name="actions" />
  </div>
</template>

<script>
import variant from '@/mixins/variant.js';
export default {
  name: 'AHeroBanner',
  mixins: [variant]
};
</script>