<template>
  <a-button 
    :class="buttonClass"
    role="checkbox"
    :aria-checked="modelValue"
    :tabindex="$attrs.disabled ? null : '0'"
    v-bind="$attrs"
    @click="onClick($event)"
  >
    <slot
      v-if="modelValue"
      name="on"
    />
    <slot
      v-else
      name="off"
    />
  </a-button>
</template>

<script>
export default {
  name: 'AToggle',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    onClass: {
      type: String,
      default: ''
    },
    offClass: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue', 'change'],
  computed: {
      buttonClass() {
          return {
              'toggle': true,
              'disabled': this.$attrs.disabled,
              'highlight': this.modelValue === true,
              [this.onClass]:  this.modelValue === true,
              [this.offClass]:  this.modelValue !== true,
          }
      }
  },
  methods: {
      onClick(event) {
          if (!this.$attrs.disabled) {
              this.$emit('update:modelValue', !this.modelValue);
              this.$emit('change', event);
          }
      }
  }
}
</script>

<style lang="scss">
button.toggle {
  i {
    @apply mr-0;
  }
}
</style>