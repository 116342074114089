<template>
  <router-link
    v-if="canViewBooth"
    :to="{ name: 'virtual.booth' }"
  >
    <a-icon
      name="handshake"
      size="lg"
      class="icon"
    />Virtual Booth
  </router-link>
  <router-link
    v-else-if="canViewExhibitors"
    :to="{ name: 'virtual.exhibitors', params: {conference: current} }"
  >
    <a-icon
      name="handshake"
      size="lg"
      class="icon"
    />Virtual Booths
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('conferences', ['current']),
    ...mapGetters('auth', ['canViewBooth', 'canViewExhibitors'])
  }
};
</script>