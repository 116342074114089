// Pollyfills

require('es6-promise/auto');
require('./services/bugsnag');

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ui from './utils/ui';
import notifications from './components/ui/notifications';
import events from "./services/events";
import './css/app.css';
import './services/zendesk'

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(events)
  .use(notifications)
  .use(ui)
  .mount('#app');
