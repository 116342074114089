import axios from 'axios';
import store from 'store';
import router from 'router';
import { get, includes, join, isArray } from '@/utils/lodash';

// eslint-disable-next-line
let baseUrl = process.env.VUE_APP_API_ROUTE;
let token = get(store, 'getters.auth.token', null);

const http = axios.create({
  baseURL: baseUrl,
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest'
    },
    Authorization: token ? `Bearer ${token}` : null
  }
});

// Add a request interceptor
http.interceptors.request.use(
  function(config) {
    let token = store.getters['auth/token'];
    let hasToken = store.getters['auth/hasToken'];

    if (hasToken) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    let message = get(error.response, 'data.message');
    let status = get(error.response, 'status');
    let hasToken = store.getters['auth/hasToken'];

    if (isArray(message)) {
      message = join(message, '<br />');
    }

    if (includes([401], status)) {
      store.dispatch('auth/setError', message);
      if (hasToken) {
        store.dispatch('auth/clearAuth');
      }
    } else if (includes([403], status)) {
      store.dispatch('auth/setError', message);

      if (
        message == 'Authentication Token Invalid, Please Login Again.' ||
        message == 'Authentication Token Expired, Please Login Again.'
      ) {
        if (hasToken) {
          store.dispatch('auth/clearAuth');
        }
        router.push({ name: 'home' });
      }
    } else if (includes([404], status)) {
      store.dispatch('alert/error', {
        message: 'Resource or Page was not found.'
      });
    } else if (includes([412], status)) {
      store.dispatch('alert/error', { message: message });
      router.go(-1);
    } else if (includes([500], status)) {
      console.error(error.response);

      store.dispatch('alert/error', {
        message: `Sorry! It seems we are having some trouble with the request. Please try again.`
      });
    }
    return Promise.reject(error);
  }
);

export default http;
