<template>
  <i :class="[type, `fa-${name}`, size ? `fa-${size}` : null, spin ? `fa-spin` : null]" />
</template>

<script>
export default {
  name: 'AIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'fal'
    },
    size: {
      type: String,
      default: undefined,
      validator: function (value) {
        return (
          [
            'xs',
            'sm',
            'lg',
            '2x',
            '3x',
            '5x',
            '7x',
            '10x'
          ].indexOf(value) !== -1
        )
      }
    },
    spin: {
      type: Boolean,
      default: false
    }
  }
};
</script>