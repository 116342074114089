import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import store from "@/store";
import { isEmpty } from "@/utils/lodash"

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    onError: function (event) {
      let user = store.getters['auth/user'];
      let conference = store.getters['conferences/conference'];

      event.addMetadata('user', user)
      event.addMetadata('conference', conference)
      if(!isEmpty(user)) {
        event.setUser(user.id, user.email, user.name)
      }
    }
  });
}