<template>
  <div
    ref="container"
    :class="containerClass"
    @click="onClick($event)"
  >
    <div class="hidden-accessible">
      <input
        :id="inputId"
        ref="focusInput"
        type="text"
        readonly
        :disabled="disabled"
        :tabindex="tabindex"
        aria-haspopup="listbox"
        :aria-expanded="overlayVisible"
        :aria-labelledby="ariaLabelledBy"
        @focus="onFocus"
        @blur="onBlur"
        @keydown="onKeyDown"
      >
    </div>
    <input
      v-if="editable"
      type="text"
      class="dropdown-label inputtext"
      :disabled="disabled"
      :placeholder="placeholder"
      :value="editableInputValue"
      aria-haspopup="listbox"
      :aria-expanded="overlayVisible"
      @focus="onFocus"
      @blur="onBlur"
      @input="onEditableInput"
    >
    <span
      v-if="!editable"
      :class="labelClass"
    >
      <slot
        name="value"
        :value="modelValue"
        :placeholder="placeholder"
      >
        {{ label }}
      </slot>
    </span>
    <a-icon
      v-if="showClear && modelValue != null"
      class="dropdown-clear-icon"
      name="times"
      @click="onClearClick($event)"
    />
    <div
      class="dropdown-trigger"
      role="button"
      aria-haspopup="listbox"
      :aria-expanded="overlayVisible"
    >
      <a-icon name="chevron-down" />
    </div>
    <transition
      name="connected-overlay"
      @enter="onOverlayEnter"
      @leave="onOverlayLeave"
    >
      <div
        v-if="overlayVisible"
        :ref="overlayRef"
        class="dropdown-panel component"
      >
        <div
          v-if="filter"
          class="dropdown-header"
        >
          <div class="dropdown-filter-container">
            <input
              ref="filterInput"
              v-model="filterValue"
              type="text"
              autoComplete="off"
              class="dropdown-filter inputtext component"
              :placeholder="filterPlaceholder"
              @keydown="onFilterKeyDown"
              @input="onFilterChange"
            >
            <a-icon name="search" />
          </div>
        </div>
        <div
          class="dropdown-items-wrapper"
          :style="{'max-height': scrollHeight}"
        >
          <ul
            class="dropdown-items"
            role="listbox"
          >
            <li
              v-for="(option, i) of visibleOptions"
              :key="getOptionRenderKey(option)"
              :class="['dropdown-item', {'highlight': isSelected(option), 'disabled': isOptionDisabled(option)}]"
              :aria-label="getOptionLabel(option)"
              role="option"
              :aria-selected="isSelected(option)"
              @click="onOptionSelect($event, option)"
            >
              <slot
                name="option"
                :option="option"
                :index="i"
              >
                {{ getOptionLabel(option) }}
              </slot>
            </li>
            <li
              v-if="filterValue && (!visibleOptions || (visibleOptions && visibleOptions.length === 0))"
              class="dropdown-empty-message"
            >
              {{ emptyFilterMessage }}
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ConnectedOverlayScrollHandler from '@/utils/scroll.js';
import ObjectUtils from '@/utils/ObjectUtils.js';
import DomHandler from '@/utils/dom.js';
export default {
  name: 'ADropdown',
  props: {
      modelValue: {
        type: [String, Number, Object, Array],
        default: null
      },
      options: {
        type: Array,
        default: () => []
      },
      optionLabel: {
        type: String,
        default: null
      },
      optionValue: {
        type: String,
        default: null
      },
      optionDisabled: {
        type: Boolean,
        default: null
      },
      scrollHeight: {
        type: String,
        default: '200px'
      },
      filter: {
        type: Boolean,
        default: false
      },
      filterPlaceholder: {
        type: String,
        default: null
      },
      filterLocale: {
        type: String,
        default: undefined
      },
      editable: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      dataKey: {
        type: String,
        default: null
      },
      showClear: {
        type: Boolean,
        default: false
      },
      inputId: {
        type: String,
        default: null
      },
      tabindex: {
        type: [String, Number],
        default: null
      },
      ariaLabelledBy: {
        type: String,
        default: null
      },
      appendTo: {
        type: String,
        default: null
      },
      emptyFilterMessage: {
          type: String,
          default: 'No results found'
      }
  },
  emits: [
    'update:modelValue',
    'before-show',
    'before-hide',
    'show',
    'hide',
    'change',
    'filter'
  ],
  data() {
      return {
          focused: false,
          filterValue: null,
          overlayVisible: false
      };
  },
  outsideClickListener: null,
  scrollHandler: null,
  resizeListener: null,
  searchTimeout: null,
  currentSearchChar: null,
  previousSearchChar: null,
  searchValue: null,
  overlay: null,
  computed: {
      visibleOptions() {
          if (this.filterValue && this.filterValue.trim().length > 0)
              return this.options.filter(option => this.getOptionLabel(option).toLocaleLowerCase(this.filterLocale).indexOf(this.filterValue.toLocaleLowerCase(this.filterLocale)) > -1);
          else
              return this.options;
      },
      containerClass() {
          return [
              'dropdown',
              {
                  'disabled': this.disabled,
                  'dropdown-clearable': this.showClear && !this.disabled,
                  'focus': this.focused,
                  'inputwrapper-filled': this.modelValue,
                  'inputwrapper-focus': this.focused
              }
          ];
      },
      labelClass() {
          return [
              'dropdown-label inputtext',
              {
                  'placeholder': this.label === this.placeholder,
                  'dropdown-label-empty': !this.$slots['value'] && (this.label === 'emptylabel' || this.label.length === 0)
              }
          ];
      },
      label() {
          let selectedOption = this.getSelectedOption();
          if (selectedOption) {
              return this.getOptionLabel(selectedOption);
          } else {
              return this.placeholder||'emptylabel';
          }
      },
      editableInputValue() {
          let selectedOption = this.getSelectedOption();
          if (selectedOption) {
              return this.getOptionLabel(selectedOption);
          } else {
              return this.modelValue;
          }
      },
      equalityKey() {
          return this.optionValue ? null : this.dataKey;
      }
  },
  beforeUnmount() {
      this.restoreAppend();
      this.unbindOutsideClickListener();
      this.unbindResizeListener();

      if (this.scrollHandler) {
          this.scrollHandler.destroy();
          this.scrollHandler = null;
      }
      this.overlay = null;
  },
  methods: {
      getOptionLabel(option) {
          return this.optionLabel ? ObjectUtils.resolveFieldData(option, this.optionLabel) : option;
      },
      getOptionValue(option) {
          return this.optionValue ? ObjectUtils.resolveFieldData(option, this.optionValue) : option;
      },
      getOptionRenderKey(option) {
          return this.dataKey ? ObjectUtils.resolveFieldData(option, this.dataKey) : this.getOptionLabel(option);
      },
      isOptionDisabled(option) {
          return this.optionDisabled ? ObjectUtils.resolveFieldData(option, this.optionDisabled) : false;
      },
      getSelectedOption() {
          let selectedOption;

          if (this.modelValue != null && this.options) {
              for (let option of this.options) {
                  if ((ObjectUtils.equals(this.modelValue, this.getOptionValue(option), this.equalityKey))) {
                      selectedOption = option;
                      break;
                  }
              }
          }

          return selectedOption;
      },
      isSelected(option) {
          return ObjectUtils.equals(this.modelValue, this.getOptionValue(option), this.equalityKey);
      },
      getSelectedOptionIndex() {
          let selectedOptionIndex = -1;

          if (this.modelValue != null && this.visibleOptions) {
              for (let i = 0; i < this.visibleOptions.length; i++) {
                  if ((ObjectUtils.equals(this.modelValue, this.getOptionValue(this.visibleOptions[i]), this.equalityKey))) {
                      selectedOptionIndex = i;
                      break;
                  }
              }
          }

          return selectedOptionIndex;
      },
      show() {
          this.$emit('before-show');
          this.overlayVisible = true;
      },
      hide() {
          this.$emit('before-hide');
          this.overlayVisible = false;
      },
      onFocus() {
          this.focused = true;
      },
      onBlur() {
          this.focused = false;
      },
      onKeyDown(event) {
          switch(event.which) {
              //down
              case 40:
                  this.onDownKey(event);
              break;

              //up
              case 38:
                  this.onUpKey(event);
              break;

              //space
              case 32:
                  if (!this.overlayVisible) {
                      this.show();
                      event.preventDefault();
                  }
              break;

              //enter and escape
              case 13:
              case 27:
                  if (this.overlayVisible) {
                      this.hide();
                      event.preventDefault();
                  }
              break;

              //tab
              case 9:
                  this.hide();
              break;

              default:
                  this.search(event);
              break;
          }
      },
      onFilterKeyDown(event) {
          switch (event.which) {
              //down
              case 40:
                  this.onDownKey(event);
                  break;

              //up
              case 38:
                  this.onUpKey(event);
                  break;

              //enter and escape
              case 13:
              case 27:
                  this.overlayVisible = false;
                  event.preventDefault();
              break;

              default:
              break;
          }
      },
      onDownKey(event) {
          if (this.visibleOptions) {
              if (!this.overlayVisible && event.altKey) {
                  this.show();
              }
              else {
                  let nextOption = this.findNextOption(this.getSelectedOptionIndex());

                  if (nextOption) {
                      this.updateModel(event, this.getOptionValue(nextOption));
                  }
              }
          }

          event.preventDefault();
      },
      onUpKey(event) {
          if (this.visibleOptions) {
              let prevOption = this.findPrevOption(this.getSelectedOptionIndex());

              if (prevOption) {
                  this.updateModel(event, this.getOptionValue(prevOption));
              }
          }

          event.preventDefault();
      },
      findNextOption(index) {
          let i = index + 1;
          if (i === this.visibleOptions.length) {
              return null;
          }

          let option = this.visibleOptions[i];
          if (this.isOptionDisabled(option))
              return this.findNextOption(i);
          else
              return option;

      },
      findPrevOption(index) {
          let i = index - 1;
          if (i < 0) {
              return null;
          }

          let option = this.visibleOptions[i];
          if (this.isOptionDisabled(option))
              return this.findPrevOption(i);
          else
              return option;
      },
      onClearClick(event) {
          this.updateModel(event, null);
      },
      onClick(event) {
          if (this.disabled) {
              return;
          }

          if (DomHandler.hasClass(event.target, 'dropdown-clear-icon') || event.target.tagName === 'INPUT') {
              return;
          }
          else if (!this.overlay || !this.overlay.contains(event.target)) {
              if (this.overlayVisible)
                  this.hide();
              else
                  this.show();

              this.$refs.focusInput.focus();
          }
      },
      onOptionSelect(event, option) {
          let value = this.getOptionValue(option);
          this.updateModel(event, value);
          this.$refs.focusInput.focus();

          this.hide();
      },
      onEditableInput(event) {
          this.$emit('update:modelValue', event.target.value);
      },
      onOverlayEnter() {
          this.overlay.style.zIndex = String(DomHandler.generateZIndex());
          this.appendContainer();
          this.alignOverlay();
          this.bindOutsideClickListener();
          this.bindScrollListener();
          this.bindResizeListener();

          if (this.filter) {
              this.$refs.filterInput.focus();
          }

          this.$emit('show');
      },
      onOverlayLeave() {
          this.unbindOutsideClickListener();
          this.unbindScrollListener();
          this.unbindResizeListener();
          this.$emit('hide');
          this.overlay = null;
      },
      alignOverlay() {
          if (this.appendTo) {
              DomHandler.absolutePosition(this.overlay, this.$el);
              this.overlay.style.minWidth = DomHandler.getOuterWidth(this.$el) + 'px';
          } else {
              DomHandler.relativePosition(this.overlay, this.$el);
          }
      },
      updateModel(event, value) {
          this.$emit('update:modelValue', value);
          this.$emit('change', {originalEvent: event, value: value});
      },
      bindOutsideClickListener() {
          if (!this.outsideClickListener) {
              this.outsideClickListener = (event) => {
                  if (this.overlayVisible && this.overlay && !this.$el.contains(event.target) && !this.overlay.contains(event.target)) {
                      this.hide();
                  }
              };
              document.addEventListener('click', this.outsideClickListener);
          }
      },
      unbindOutsideClickListener() {
          if (this.outsideClickListener) {
              document.removeEventListener('click', this.outsideClickListener);
              this.outsideClickListener = null;
          }
      },
      bindScrollListener() {
          if (!this.scrollHandler) {
              this.scrollHandler = new ConnectedOverlayScrollHandler(this.$refs.container, () => {
                  if (this.overlayVisible) {
                      this.hide();
                  }
              });
          }

          this.scrollHandler.bindScrollListener();
      },
      unbindScrollListener() {
          if (this.scrollHandler) {
              this.scrollHandler.unbindScrollListener();
          }
      },
      bindResizeListener() {
          if (!this.resizeListener) {
              this.resizeListener = () => {
                  if (this.overlayVisible) {
                      this.hide();
                  }
              };
              window.addEventListener('resize', this.resizeListener);
          }
      },
      unbindResizeListener() {
          if (this.resizeListener) {
              window.removeEventListener('resize', this.resizeListener);
              this.resizeListener = null;
          }
      },
      search(event) {
          if (!this.visibleOptions) {
              return;
          }

          if (this.searchTimeout) {
              clearTimeout(this.searchTimeout);
          }

          const char = String.fromCharCode(event.keyCode);
          this.previousSearchChar = this.currentSearchChar;
          this.currentSearchChar = char;

          if (this.previousSearchChar === this.currentSearchChar)
              this.searchValue = this.currentSearchChar;
          else
              this.searchValue = this.searchValue ? this.searchValue + char : char;

          let searchIndex = this.getSelectedOptionIndex();
          let newOption = this.searchOption(++searchIndex);

          if (newOption) {
              this.updateModel(event, this.getOptionValue(newOption));
          }

          this.searchTimeout = setTimeout(() => {
              this.searchValue = null;
          }, 250);
      },
      searchOption(index) {
          let option;

          if (this.searchValue) {
              option = this.searchOptionInRange(index, this.visibleOptions.length);

              if (!option) {
                  option = this.searchOptionInRange(0, index);
              }
          }

          return option;
      },
      searchOptionInRange(start, end) {
          for (let i = start; i < end; i++) {
              let opt = this.visibleOptions[i];
              let label = this.getOptionLabel(opt).toLocaleLowerCase(this.filterLocale);
              if (label.startsWith(this.searchValue.toLocaleLowerCase(this.filterLocale))) {
                  return opt;
              }
          }

          return null;
      },
      appendContainer() {
          if (this.appendTo) {
              if (this.appendTo === 'body')
                  document.body.appendChild(this.overlay);
              else
                  document.getElementById(this.appendTo).appendChild(this.overlay);
          }
      },
      restoreAppend() {
          if (this.overlay && this.appendTo) {
              if (this.appendTo === 'body')
                  document.body.removeChild(this.overlay);
              else
                  document.getElementById(this.appendTo).removeChild(this.overlay);
          }
      },
      onFilterChange(event) {
          this.$emit('filter', {originalEvent: event, value: event.target.value});
          if (this.overlayVisible) {
              this.alignOverlay();
          }
      },
      overlayRef(el) {
          this.overlay = el;
      }
  }
}
</script>
