<template>
  <slot />
</template>

<script>
export default {
  name: 'AAccordionTab',
  props: {
    header: {
      type: [String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>