<template>
  <button
    class="px-4 border-r border-cool-gray-200 text-cool-gray-400 focus:outline-none focus:bg-cool-gray-100 focus:text-cool-gray-600 md:hidden"
    aria-label="Open sidebar"
    @click="onClick"
  >
    <svg
      class="h-6 w-6 transition ease-in-out duration-150"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 6h16M4 12h8m-8 6h16"
      />
    </svg>
  </button>
</template>

<script>
export default {
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>