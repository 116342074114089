import http from "@/services/http";
import { includes, get, map } from "@/utils/lodash";

const state = {};

const getters = {
  currentConference: (state, getters, rootState, rootGetters) => rootGetters['conferences/current'],
  conference: (state, getters, rootState, rootGetters) => rootGetters['conferences/conference'],
  managers: (state, getters) => get(getters.conference, 'managers'),
  isManager: (state, getters, rootState, rootGetters) => {
    let id = rootGetters['auth/id'];
    let managers = map(getters.managers, 'id');

    return includes(managers, id);
  }
};

const actions = {
  async attendees({ getters: { currentConference } }) {
    let { data: { data } } = await http.get(`conferences/${currentConference}/management/attendees`);

    return data; 
  },
  async attendee({ getters: { currentConference } }, id) {
    let { data: { data } } = await http.get(`conferences/${currentConference}/management/attendees/${id}`);

    return data; 
  },
  async attendeeSchedule({ getters: { currentConference } }, id) {
    let { data: { data } } = await http.get(`conferences/${currentConference}/management/attendees/${id}/schedule`);

    return data; 
  },
  async meetings({ getters: { currentConference } }) {
    let { data: { data } } = await http.get(`conferences/${currentConference}/management/meetings`);

    return data; 
  },
  async upcomingSchedule({ getters: { currentConference } }) {
    let { data: { data } } = await http.get(`conferences/${currentConference}/management/meetings/upcoming`);

    return data; 
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
