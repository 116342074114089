import { get, isEmpty, head, map, includes } from 'utils/lodash';
import http from '@/services/http';
import { getCookie, setCookie, removeCookie } from 'tiny-cookie';

const state = {
  user: null,
  expires: null,
  token: getCookie('token'),
  error: null
};

// getters
const getters = {
  id: state => get(state.user, 'id'),
  isUser: state => !isEmpty(state.user),
  user: state => state.user,
  token: state => state.token,
  hasToken: state => !isEmpty(state.token),
  error: state => state.error,
  name: state => get(state.user, 'name'),
  hash: state => get(state.user, 'hash'),
  email: state => get(state.user, 'email'),
  initials: state => get(state.user, 'initials'),
  type: state => get(state.user, 'type'),
  booths: state => get(state.user, 'booths'),
  booth: (state, getters) => head(getters.booths),
  hasBooth: (state, getters) => !isEmpty(getters.booths),
  conference: (state, getters, rootState, rootGetters) =>
    rootGetters['conferences/current'],
    isBoothManager: (state, getters, rootState, rootGetters) => exhibitor => {
      const managers = map(get(exhibitor, 'managers'), 'id');
      const isBoothManager = includes(managers, getters.id);
      return rootGetters['management/isManager'] || (getters.canViewBooth && isBoothManager)
    },
  isBuyer: (state, getters) => get(getters.type, 'isBuyer', false),
  isExhibitor: (state, getters) => get(getters.type, 'isExhibitor', false),
  canViewBooth: (state, getters) => getters.isExhibitor && getters.hasBooth,
  canViewExhibitors: (state, getters, rootState, rootGetters) => getters.isBuyer || rootGetters['management/isManager']
};

// actions
const actions = {
  async login({ commit, getters }, fields) {
    try {
      let {
        data: { data }
      } = await http.post(
        `conferences/${getters.conference}/user/login`,
        fields
      );

      commit('USER_AUTH', data);

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async resetEmail({ getters }, fields) {
    try {
      let {
        data: { data }
      } = await http.post(
        `conferences/${getters.conference}/user/password/email`,
        fields
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async resetPassword({ getters }, fields) {
    try {
      let {
        data: { data }
      } = await http.post(
        `conferences/${getters.conference}/user/password/reset`,
        fields
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async me({ commit, getters }) {
    try {
      let {
        data: { data }
      } = await http.get(`conferences/${getters.conference}/user/me`);
      commit('USER_ME', data);
      return data;
    } catch (e) {
      commit('USER_AUTH_CLEAR');
      return Promise.reject(e);
    }
  },
  async logout({ dispatch, getters }) {
    try {
      http.post(`conferences/${getters.conference}/user/logout`);
    } catch (e) {
      console.error(e);
    }
    dispatch('clearAuth');
  },
   async clearAuth({ commit, dispatch }) {
    await dispatch('impersonate/clearImpersonating', null, {root: true})
    commit('USER_AUTH_CLEAR');
  },
  async setError({ commit }, error) {
    await commit('SET_ERROR', error);
  },
  async clearError({ commit }) {
    await commit('CLEAR_ERROR');
  },
  setUserAuth({ commit }, payload) {
    commit('USER_AUTH', payload);
  }
};

// mutations
const mutations = {
  USER_AUTH(state, data) {
    state.token = data.access_token;
    state.expires = data.expires;
    state.user = data.user;

    setCookie('token', state.token);
  },
  USER_ME(state, user) {
    state.user = user;
  },
  USER_AUTH_CLEAR(state) {
    state.token = null;
    state.expires = null;
    state.user = null;

    removeCookie('token');
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  CLEAR_ERROR(state) {
    state.error = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
