export default {
  props: {
    size: {
      type: String,
      default: 'md',
      validator: function (value) {
        return (
          [
            'xxs',
            'xs',
            'sm',
            'md',
            'lg',
            'xl',
            '2xl',
            '3xl',
            '4xl'
          ].indexOf(value) !== -1
        )
      }
    }
  },
  computed: {
    sizeClass () {
      return `size--${this.size}`
    }
  }
}
