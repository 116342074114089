<template>
  <div class="flex justify-center p-2">
    <img
      v-if="hasLogo"
      :src="source"
      alt="Conference Logo"
    >
    <img
      v-else
      src="@/assets/images/avn_logo.png"
      :class="{'h-12': small}"
      alt="Logo"
    >
  </div>
</template>

<script>
import { get, isEmpty } from '@/utils/lodash';
import { resize } from '@/utils/resize';
export default {
  props: {
    conference: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 200
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    image() {
      return get(this.conference, 'logo.path');
    },
    hasLogo() {
      return !isEmpty(this.image);
    },
    source() {
      return resize(this.image, this.width, this.height, 'center', 'contain');
    }
  }
};
</script>