<template>
  <div
    role="progressbar"
    :class="containerClass"
    aria-valuemin="0"
    :aria-valuenow="value"
    aria-valuemax="100"
  >
    <div
      v-if="determinate"
      class="progressbar-value progressbar-value-animate"
      :style="progressStyle"
    />
    <div
      v-if="determinate && value && showValue"
      :class="['progressbar-label', {'half': value >= 48}]"
    >
      <slot>{{ value + '%' }}</slot>
    </div>
    <div
      v-if="indeterminate"
      class="progressbar-indeterminate-container"
    >
      <div class="progressbar-value progressbar-value-animate" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AProgressBar',
  props: {
    value: {
      type: Number,
      default: 0
    },
    mode: {
        type: String,
        default: 'determinate'
    },
    showValue: {
        type: Boolean,
        default: true
    }
  },
  computed: {
    containerClass() {
        return [
            'progressbar',
            {
                'progressbar-determinate': this.determinate,
                'progressbar-indeterminate': this.indeterminate
            }
        ];
    },
    progressStyle() {
        return {
            width: this.value + '%',
            display: 'block'
        };
    },
    indeterminate() {
        return this.mode === 'indeterminate';
    },
    determinate() {
        return this.mode === 'determinate';
    }
  }
}
</script>

<style>
.progressbar {
  @apply border-0 h-6 bg-gray-200 rounded-md relative overflow-hidden;
}
.progressbar .progressbar-value {
  @apply m-0 border-0 bg-primary;
}
.progressbar .progressbar-label {
  @apply text-gray-400 leading-6;
}
.progressbar .progressbar-label.half {
  @apply text-white leading-6;
}

.progressbar-determinate .progressbar-value {
  @apply h-full w-0 absolute hidden border-0;
}

.progressbar-determinate .progressbar-value-animate {
    transition: width 1s ease-in-out;
}

.progressbar-determinate .progressbar-label {
  @apply text-center h-full w-full absolute font-medium;
}

.progressbar-indeterminate .progressbar-value::before {
  @apply absolute top-0 left-0 bottom-0;
      content: '';
      background-color: inherit;
      will-change: left, right;
      -webkit-animation: progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
              animation: progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progressbar-indeterminate .progressbar-value::after {
  @apply absolute top-0 left-0 bottom-0;
    content: '';
    background-color: inherit;
    will-change: left, right;
    -webkit-animation: progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation: progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    -webkit-animation-delay: 1.15s;
            animation-delay: 1.15s;
}

@-webkit-keyframes progressbar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; }
}
@keyframes progressbar-indeterminate-anim {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; }
}

@-webkit-keyframes progressbar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; }
}
@keyframes progressbar-indeterminate-anim-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; }
}
</style>