<template>
  <div>
    <div class="border-b border-gray-300 my-2" />
    <h2 class="text-red-800">
      Management
    </h2>
    <router-link :to="{ name: 'management.index', params: {conference: current} }">
      <a-icon
        name="cogs"
        size="lg"
        class="icon"
      />Dashboard
    </router-link>
    <router-link :to="{ name: 'management.attendees', params: {conference: current} }">
      <a-icon
        name="users-class"
        size="lg"
        class="icon"
      />Attendees
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('conferences', ['current'])
  }
};
</script>


<style scoped>
h2 {
  @apply flex items-center px-2 py-2 text-lg leading-7 rounded-md focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150;
}

a {
  @apply group flex items-center px-2 py-2 pl-4 text-base leading-5 text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:text-gray-900 focus:bg-gray-100 transition ease-in-out duration-150;

  &.router-link-exact-active {
    @apply text-gray-800 bg-gray-200 hover:text-gray-900 hover:bg-gray-200 focus:bg-gray-200;
  }
}

.icon {
  @apply flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150;
}
</style>