<template>
  <div
    v-if="hasNotifications"
    :class="notificationsClass"
  >
    <div :class="['notifications__container', notificationContainerClasses]">
      <transition-group
        :name="animate ? 'notifications' : 'none'"
        :class="{
          'animation--top': isTopPosition,
          'animation--bottom': isBottomPosition
        }"
        tag="div"
      >
        <notification
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
          :expand="expand"
          :variant="notification.variant || ''"
          :close-on-click="closeOnClick"
          :pause-on-hover="pauseOnHover"
          @close="onClose"
          @click="onClick"
        >
          <slot :notification="notification" />
        </notification>
      </transition-group>
    </div>
  </div>
</template>

<script>
import Notification from './notification.vue';
import { uniqueId } from '@/utils/helpers.js';
import { filter, isEmpty, includes } from '@/utils/lodash.js';

const SIZES = [null, 'half', 'full'];
const EXPANDS = [false, true, 'hover', 'click'];
const POSITIONS = ['top', 'bottom', 'right', 'left', 'center'];

export default {
  name: 'ANotification',
  components: {
    Notification
  },
  props: {
    group: {
      type: [String, Number],
      default: null
    },
    position: {
      type: [String, Array],
      default: () => ['top', 'right'],
      validator: function(value) {
        let positions = value;

        if (typeof value === 'string') {
          positions = value.split(' ');
        }

        positions = filter(positions, i => !isEmpty(i));

        return positions.every(p => includes(POSITIONS, p));
      }
    },
    duration: {
      type: Number,
      default: 7000
    },
    size: {
      type: String,
      default: null,
      validator: value => SIZES.includes(value)
    },
    expand: {
      type: [Boolean, String],
      default: false,
      validator: value => EXPANDS.includes(value)
    },
    animate: {
      type: Boolean,
      default: true
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    pauseOnHover: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    notifications: []
  }),
  computed: {
    isTopPosition() {
      return includes(this.positions, ['top']);
    },
    isBottomPosition() {
      return includes(this.positions, ['bottom']);
    },
    groupedNotifications() {
      return filter(this.notifications, n => n.group === this.group);
    },
    hasNotifications() {
      return !isEmpty(this.groupedNotifications);
    },
    notificationsClass() {
      return ['notifications', this.sizeClass, this.positionsClasses];
    },
    notificationContainerClasses() {
      return this.positions.map(
        p => `notifications__container--position--${p}`
      );
    },
    positions() {
      if (typeof this.position === 'string') {
        return this.position.split(' ');
      }

      return this.position;
    },
    sizeClass() {
      if (!this.size) {
        return;
      }

      return `notifications--size--${this.size}`;
    },
    positionsClasses() {
      return this.positions.map(p => `notifications--position--${p}`);
    }
  },
  created() {
    this.$events.on('create', this.onCreateNotification);
    this.$events.on('remove', this.onRemoveNotification);
    this.$events.on('clear', this.onClearNotifications);
    this.$events.on('clear-all', this.onClearAllNotifications);
  },
  beforeUnmount() {
    this.$events.off('create', this.onCreateNotification);
    this.$events.off('remove', this.onRemoveNotification);
    this.$events.off('clear', this.onClearNotifications);
    this.$events.off('clear-all', this.onClearAllNotifications);
  },
  methods: {
    onCreateNotification({ variant, params: event }) {
      const group = event.group || null;
      if (this.group !== group) {
        return;
      }

      const duration =
        typeof event.duration === 'number' ? event.duration : this.duration;

      const {
        dismissible,
        title,
        text,
        html,
        id,
        icon,
        onClick,
        onHover,
        onBlur,
        onClose,
        onTime
      } = event;

      const notification = {
        id: id || uniqueId(),
        dismissible: dismissible !== undefined ? dismissible : true,
        title,
        text,
        html,
        variant,
        group,
        icon,
        duration,
        onClick,
        onHover,
        onBlur,
        onClose,
        onTime
      };

      this.notifications.push(notification);
    },
    onClearNotifications(group) {
      group = group || null;
      if (this.group !== group) {
        return;
      }

      this.notifications = [];
    },
    onClearAllNotifications() {
      this.notifications.forEach(this.onClose);
    },
    onRemoveNotification(id) {
      this.onClose({ id });
    },
    onClose({ id }) {
      this.notifications = filter(
        this.notifications,
        notification => notification.id !== id
      );
    },
    onClick({ id, dismissible }) {
      if (this.closeOnClick && dismissible) {
        this.onClose({ id });
      }
    }
  }
};
</script>
