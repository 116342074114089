import Swal from 'sweetalert2';
import { merge } from 'utils/lodash';

const state = {};

const getters = {};

const actions = {
  async error({ dispatch }, payload) {
    await dispatch('fire', merge(payload, { type: 'error' }));
  },
  async success({ dispatch }, payload) {
    await dispatch('fire', merge(payload, { type: 'success' }));
  },
  async warning({ dispatch }, payload) {
    await dispatch('fire', merge(payload, { type: 'warning' }));
  },
  async info({ dispatch }, payload) {
    await dispatch('fire', merge(payload, { type: 'info' }));
  },
  // eslint-disable-next-line no-empty-pattern
  async fire({}, { title, message, closeText, timer }) {
    await Swal.fire({
      title: title || 'Error!',
      html: message,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: closeText || 'Close',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-dark'
      },
      timer: timer || undefined
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
