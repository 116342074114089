<template>
  <router-view />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    conference: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('conferences', ['conferences', 'find']),
    isLoaded() {
      return this.find(this.conference);
    }
  },
  watch: {
    conference: {
      immediate: true,
      handler: 'checkForConference'
    }
  },
  methods: {
    ...mapActions('conferences', ['setCurrent', 'get']),
    async checkForConference() {
      this.setCurrent(this.conference);

      try {
        await this.get(this.conference);
        this.setCurrent(this.conference);
      } catch (e) {
        this.$router.replace({ name: 'home' });
      }
    }
  }
};
</script>
