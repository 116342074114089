import http from '@/services/http';
import { get, isEmpty, head } from "@/utils/lodash"

const state = {
  exhibitor: {}
};

const getters = {
  exhibitor: state => state.exhibitor,
  loaded: state => !isEmpty(state.exhibitor),
  slug: state => get(state.exhibitor, 'slug'),
  title: state => get(state.exhibitor, 'title'),
  description: state => get(state.exhibitor, 'description'),
  bannerImage: state => get(state.exhibitor, 'banner'),
  logoImage: state => get(state.exhibitor, 'logo'),
  contacts: state => get(state.exhibitor, 'contacts'),
  products: state => get(state.exhibitor, 'products'),
  videos: state => get(state.exhibitor, 'videos'),
  video: (state, getters) => head(getters.videos),
  hasVideo: (state, getters) => !isEmpty(getters.video),
  hasVideos: (state, getters) => !isEmpty(getters.videos),
  conference: (state, getters, rootState, rootGetters) =>
    rootGetters['conferences/current']
};

const actions = {
  async get({ commit, getters }, id) {
    try {
      const { data: { data } } = await http.get(`conferences/${getters.conference}/exhibitors/${id}`)

      commit('SET_CURRENT_EXHIBITOR', data)

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  async update({ commit, getters }, {exhibitor, form}) {
    try {
      const { data: { data } } = await http.put(`conferences/${getters.conference}/exhibitors/${exhibitor}`, form)

      commit('SET_CURRENT_EXHIBITOR', data)

      return data;
    } catch(e) {
      return Promise.reject(e);
    }
  },
  clear({ commit }) {
    commit('CLEAR_CURRENT_EXHIBITOR')
  }
};

const mutations = {
  SET_CURRENT_EXHIBITOR(state, row) {
    state.exhibitor = row;
  },
  CLEAR_CURRENT_EXHIBITOR(state) {
    state.exhibitor = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
