<template>
  <p :class="{error}">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'AHelp',
  props: {
    error: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  @apply mt-1 text-xs text-gray-600;

  &.error {
    @apply text-danger;
  }
}
</style>